import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import { useLocation } from '@reach/router';

import { datoGatsbyImageProp, refProp, authorProp } from '../../../types';

import { createHeadingHighlightCA } from '../../../utils/heading-highlight-ca';
import { PBSLink } from '../link';
import { Button } from '../button-ca';

import { getMultiParagraphText } from '../../../utils';
import { useDrawer } from '../../../contexts/drawer-context';
import { buildLink, getLocale } from '../../../utils/locale';

const CtaBannerV2 = ({
	id,
	content,
	link,
	linkCopy,
	author,
	image,
	ctaOpenForm,
	structuredTitle,
	className,
	drawerRef,
	onClickProp,
}) => {
	const { setDrawerRef } = useDrawer();
	const location = useLocation();
	const locale = getLocale(location?.pathname);
	// let alt = '';
	// let imageData = {};
	// if (image && Object.keys(image).length !== 0) {
	// 	alt = image.alt || 'Peninsula HR & Health and Safty Support';
	// 	imageData = getImage(image);
	// }
	const contentArr = content.split('\n');
	const paragraphClassNames =
		'text-md mb-3 last-of-type:mb-0 text-center md:text-left break-words';

	let cta;
	if (link) {
		cta = (
			<PBSLink
				to={buildLink(locale, link)}
				className="text-center !font-centra-book"
				onClick={() => {
					onClickProp({
						click_text: linkCopy,
						component_name: 'cta-banner',
					});
				}}
			>
				{linkCopy}
			</PBSLink>
		);
	} else if (ctaOpenForm) {
		cta = (
			<Button
				className="text-center"
				onClick={() => {
					setDrawerRef(drawerRef);
					onClickProp({
						click_text: linkCopy,
						component_name: 'cta-banner',
					});
				}}
			>
				{linkCopy}
			</Button>
		);
	}

	return (
		<article
			className={clsx(
				'bg-[#EDF4FF] border-[#C4CEFF] border-1 rounded-md mt-20 flex flex-col md:grid md:gap-3 lg:mb-0 ',
				className,
				'md:grid-cols-[1fr_37%]'
			)}
		>
			{/* Text section */}
			<div
				className={clsx(
					'flex flex-col justify-center p-8 order-2 sm:order-1'
				)}
			>
				<h2 className="flex items-center mt-5 xl:mt-0 justify-start pb-4 mb-5 font-centra-medium text-md border-[#C4CEFF] border-b-1">
					<div className="text-sm text-[#303030] font-centra-bold leading-tight mr-4">
						Webinar <br /> speakers:
					</div>

					<div className="flex items-center space-x-3">
						<img
							src={author.image.url}
							alt={author.name}
							className="w-12 h-12 rounded-full"
						/>
						<div>
							<p className="text-[#3954E0] font-centra-bold leading-tight">
								{author.name}
							</p>
							<p className="text-[#303030] text-xs leading-tight">
								{author.jobTitle}
							</p>
						</div>
					</div>
				</h2>

				{structuredTitle?.value?.document?.children?.[0]?.children
					?.length > 0 &&
					createHeadingHighlightCA({
						headings:
							structuredTitle.value.document.children[0].children,
						variant: 'red-2',
						className: 'lg:text-4xl mb-4',
					})}

				{getMultiParagraphText(contentArr, paragraphClassNames, id)}

				<div className="flex flex-col items-center mt-5 mb-5 xl:mb-0 lg:flex-row">
					{cta ? (
						<>
							{cta}
							<hr
								aria-hidden="true"
								className="lg:block lg:h-full lg:min-h-full lg:mx-7"
							/>
						</>
					) : null}
				</div>
			</div>
			{/* Image section */}
			<div className="order-2 sm:order-2 w-[90%] sm:mt-0 xl:-mt-14 lg:-mt-10 md:my-auto sm:mx-auto">
				<img
					src={image.url}
					alt="Peninsula HR & Health and Safety Support"
					className=""
				/>
			</div>
		</article>
	);
};

CtaBannerV2.defaultProps = {
	className: '',
	ctaOpenForm: true,
	onClickProp: () => {},
	author: '',
};

CtaBannerV2.propTypes = {
	id: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
	linkCopy: PropTypes.string.isRequired,
	className: PropTypes.string,
	image: datoGatsbyImageProp.isRequired,
	structuredTitle: PropTypes.string.isRequired,
	ctaOpenForm: PropTypes.bool,
	drawerRef: refProp.isRequired,
	onClickProp: PropTypes.func,
	author: authorProp,
};

export { CtaBannerV2 };
