import { clsx } from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { isHeading, isParagraph, isRoot } from 'datocms-structured-text-utils';
import { StructuredText, renderNodeRule } from 'react-datocms';
import NumberOne from '../../../assets/1.png';
import NumberTwo from '../../../assets/2.png';
import NumberThree from '../../../assets/3.png';
import SafeCheckArrowUp from '../../../assets/safecheck-arrow-up.svg';
import SafeCheckReady from '../../../assets/safecheck-ready.svg';
import SafeCheckStop from '../../../assets/safecheck-stop.svg';
import { useDrawer } from '../../../contexts/drawer-context';
import { refProp } from '../../../types';
import { sendTrackingData } from '../../../utils';
import { Button } from '../../atoms/button';
import { CallUs } from '../../atoms/call-us';

const SafeCheckTextListContent = ({
	textContent,
	drawerRef,
	listContent,
	pageName,
}) => {
	const { id, title, content, ctaText, ctaOpenForm } = textContent;
	const { setDrawerRef } = useDrawer();

	const handleClickTracking = (trackData) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'image-content',
			click_type: 'secondary',
			click_title: title,
			...trackData,
		});
	};
	const safecheckImage = (imageType) => {
		switch (imageType) {
			case 'stop':
				return SafeCheckStop;
			case 'ready':
				return SafeCheckReady;
			case 'arrow_up':
				return SafeCheckArrowUp;
			case 'one':
				return NumberOne;
			case 'two':
				return NumberTwo;
			case 'three':
				return NumberThree;
			default:
				return SafeCheckStop;
		}
	};
	return (
		<section
			className="max-w-m-screen mx-auto lg:container"
			aria-labelledby={id}
		>
			<div
				className={clsx(
					'py-10 rounded-sm m-2 grid md:grid-cols-2 grid-cols-1 xl:gap-4 md:gap-4 gap-3',
					'md:px-0 md:py-10 xl:px-10 xl:py-20',
					'bg-brand-pale-400 w-full'
				)}
			>
				<div className="flex flex-col md:space-y-10 space-y-5 xl:px-10 md:px-2 px-2 ">
					<div className="xl:space-y-3 md:space-y-2 space-y-1">
						<div className="text-3xl font-castledown-regular leading-[1.2] text-left  md:!text-4xl md:!leading-normal lg:max-w-[470px]">
							<StructuredText
								data={title}
								customNodeRules={[
									renderNodeRule(
										isHeading,
										({ node, children, key }) => {
											const HeadingTag = `h${node.level}`;
											return (
												<HeadingTag
													className="text-3xl font-castledown-regular leading-[1.2]  text-left md:!text-4xl md:!leading-normal lg:max-w-[470px]"
													key={key}
												>
													{children}
												</HeadingTag>
											);
										}
									),
								]}
							/>
						</div>
						<StructuredText
							data={content}
							customNodeRules={[
								renderNodeRule(
									isParagraph,
									({ children, key, ancestors }) => {
										if (isRoot(ancestors[0])) {
											return (
												<p
													className="mb-0 text-lg font-centra-light md:text-xl last-of-type:mb-0 text-left"
													key={key}
												>
													{children}
												</p>
											);
										}
										return children;
									}
								),
							]}
						/>
					</div>
					{ctaOpenForm && (
						<div
							className={clsx(
								'flex',
								'flex-wrap md:flex-nowrap w-full gap-1',
								'md:flex-row',
								'items-center',
								'justify-center',
								'md:justify-start',
								'md:items-start',
								'lg:space-y-0',
								'lg:space-x-8',
								'lg:flex-row',
								'lg:items-center'
							)}
						>
							<Button
								onClick={() => {
									setDrawerRef(drawerRef);
									handleClickTracking(ctaText);
								}}
								variant="White"
								className="justify-start text-sm md:text-lg"
							>
								{ctaText}
							</Button>
							<hr
								aria-hidden="true"
								className="my-2 md:hidden xl:block md:h-full md:min-h-[60px] md:mx-7 md:border-l-1 md:border-l-blue-300"
							/>
							<CallUs
								pageName={pageName}
								altMobile
								className="md:border-0 md:bg-transparent"
							/>
						</div>
					)}
				</div>
				<div className="flex flex-col gap-4 xl:px-10 md:px-5 px-4">
					{listContent.map((item) => (
						<div
							key={item?.id}
							className="flex xl:p-5 md:p-3 p-2 border md:rounded-md rounded-sm bg-white gap-8 hover:shadow-xl transition-all duration-300 ease-in-out"
						>
							<img
								src={safecheckImage(item.imageType)}
								alt="placeholder"
								className="w-16 h-16 md:w-20 md:h-20 object-contain"
							/>
							<p className="flex items-center text-lg font-centra-light md:text-xl last-of-type:mb-0">
								{item.content}
							</p>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

SafeCheckTextListContent.defaultProps = {
	textContent: {
		as: 'h2',
	},
	pageName: 'safecheck',
};

SafeCheckTextListContent.propTypes = {
	textContent: PropTypes.shape({
		id: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		content: PropTypes.string.isRequired,
		ctaText: PropTypes.string.isRequired,
		ctaOpenForm: PropTypes.bool.isRequired,
	}),
	drawerRef: refProp.isRequired,
	pageName: PropTypes.string,
	listContent: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export { SafeCheckTextListContent };
