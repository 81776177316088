// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { BreadCrumbs } from '../components/atoms/breadcrumbs';
import { Drawer } from '../components/atoms/drawer';
import { IconBanner } from '../components/atoms/icon-banner';
import { Title } from '../components/atoms/title';
import { TrustpilotBusinessReviewHorizontal } from '../components/atoms/trustpilot-business-review';
import { Vimeo } from '../components/atoms/vimeo';
import { DefaultLayout } from '../layouts/default';
import { DefaultLayout as DefaultLayoutPpcV2 } from '../layouts/default/ppc-v2-new';

import { TextWithImageContainer } from '../components/molecules/text-with-image-container';
import { TrustpilotReviews } from '../components/molecules/trustpilot-reviews';
import { TwoColText } from '../components/molecules/two-col-text';

import { AdviceBanner } from '../components/organisms/advice-banner';
import { AdviceForm } from '../components/organisms/advice-form';
import { AdviceFormCA } from '../components/organisms/ca/forms/salesforce/advice';
import { SuccessFormCA } from '../components/organisms/ca/forms/salesforce/success';
import { IconCards } from '../components/organisms/icon-cards';
import { SuccessForm } from '../components/organisms/success-form';
import { Testimonials } from '../components/organisms/testimonials';

import { Head } from '../components/templates/head';

import { getBreadcrumbsSeoSchema } from '../utils/seo-schema';
import { getBreadcrumbs, pageTypes } from '../utils/url-helpers';

import { HrefLang } from '../components/templates/hrefLang';
import { useDrawer } from '../contexts/drawer-context';

const AboutUsPage = ({ location, data }) => {
	const { drawerRef } = useDrawer();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');
	const [categoryParam, setCategoryParam] = useState(null);
	const [ppcHeaderIndex, setPpcHeaderIndex] = useState(-1);

	// Check if the code is running in the browser
	useEffect(() => {
		if (typeof window !== 'undefined') {
			const urlParams = new URLSearchParams(window.location.search);
			const category = urlParams.get('category');
			if (category) {
				// Clean category value by removing slashes
				setCategoryParam(category.replace(/^\/+|\/+$/g, ''));
			}
		}
	}, []);

	// Update ppcHeaderIndex when categoryParam changes
	useEffect(() => {
		if (categoryParam) {
			const ppcHeaderArr = data?.allDatoCmsPpcPage?.nodes || [];
			const index = ppcHeaderArr.findIndex(
				(elm) => elm?.siteNavigation[0]?.category === categoryParam
			);
			setPpcHeaderIndex(index);
		}
	}, [categoryParam, data?.allDatoCmsPpcPage?.nodes]);

	const metaContent = {
		noIndex: data.datoCmsAboutUsPage.noIndex,
		noFollow: data.datoCmsAboutUsPage.noFollow,
		metaInformation: data.datoCmsAboutUsPage.metaInformation,
		fallbackTitle: data.datoCmsAboutUsPage.title,
		fallbackDescription: data.datoCmsAboutUsPage.longIntroduction,
		canonicalUrl: data.datoCmsAboutUsPage?.canonicalUrl || location.href,
	};

	const { locale } = data.datoCmsAboutUsPage;

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.aboutUs,
			location,
		}),
	};

	const textWithImageContainer = {
		...data.datoCmsAboutUsPage.textWithImageContainer,
		title: data.datoCmsAboutUsPage.textWithImageContainer.title.value
			.document.children,
		firstContent:
			data.datoCmsAboutUsPage.textWithImageContainer.firstContent,
		secondContent:
			data.datoCmsAboutUsPage.textWithImageContainer.secondContent,
	};

	const testimonialsContent = {
		...data.datoCmsAboutUsPage.testimonials,
		title: data.datoCmsAboutUsPage.testimonials.title.value.document
			.children,
		slides: data.datoCmsAboutUsPage.testimonials.slides.map((slide) => ({
			...slide,
			tags: slide.tags.split(','),
		})),
	};

	const twoColumnText = {
		...data.datoCmsAboutUsPage.twoColumnText,
		title: data.datoCmsAboutUsPage.twoColumnText.title.value.document
			.children,
		content:
			data.datoCmsAboutUsPage.twoColumnText.content.value.document
				.children,
	};

	const adviceBannerContent = {
		...data.datoCmsAboutUsPage.adviceBanner,
		title: data.datoCmsAboutUsPage.adviceBanner.title.value.document
			.children,
	};

	const breadCrumbItems = getBreadcrumbs(
		data.datoCmsAboutUsPage.internal.type,
		data.datoCmsAboutUsPage
	);

	const historyTimeline = {
		uspCards: data.datoCmsAboutUsPage.uspContainer.uspCards,
		title: data.datoCmsAboutUsPage.uspContainer.title.value.document
			.children,
		subText: data.datoCmsAboutUsPage.uspContainer.subText,
	};
	// const { locale: urlLocale } = data.datoCmsAboutUsPage;
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			{ppcHeaderIndex === -1 && (
				<BreadCrumbs breadCrumbItems={breadCrumbItems} />
			)}
			<HrefLang locales={data.datoCmsAboutUsPage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<Title
				title={data.datoCmsAboutUsPage.heading}
				subtitle={data.datoCmsAboutUsPage.subHeading}
			/>
			{locale !== 'en-CA' ? (
				<TrustpilotBusinessReviewHorizontal
					className="justify-center !mt-0 px-3"
					textClassName="text-center"
					rating={data.datoCmsTrustpilotRating}
				/>
			) : null}
			{locale !== 'en-CA' ? (
				<IconBanner data={data.datoCmsAboutUsPage.iconBanner} />
			) : null}
			<TextWithImageContainer
				textWithImageContainerContent={textWithImageContainer}
			/>
			<Vimeo
				containerClassName="mx-auto my-2xl-f w-full md:my-3xl-f lg:max-w-[865px]"
				video={data.datoCmsAboutUsPage.primaryVideo.url}
				id={data.datoCmsAboutUsPage.primaryVideo.id}
				videoTitle={data.datoCmsAboutUsPage.primaryVideoTitle}
				videoLength={data.datoCmsAboutUsPage.primaryVideoLength}
				responsive
			/>
			{data.datoCmsAboutUsPage.uspContainer && (
				<IconCards
					iconCardsContent={historyTimeline}
					isTimeline
					variant="StandardTextCenter"
					showContentLinks={false}
				/>
			)}
			<Testimonials
				drawerRef={adviceFormRef}
				testimonialsContent={testimonialsContent}
				className="border-b-4 bg-brand-pale-400 md:border-b-6 border-b-blue-400"
			/>
			{data.datoCmsAboutUsPage.secondaryVideoDisplayOnPage &&
			data.datoCmsAboutUsPage.secondaryVideo.url ? (
				<Vimeo
					containerClassName="mx-auto mt-2xl-f mb-s-f md:mb-lg-f w-full md:mt-3xl-f lg:max-w-[865px]"
					video={data.datoCmsAboutUsPage.secondaryVideo.url}
					id={data.datoCmsAboutUsPage.secondaryVideo.id}
					videoTitle={data.datoCmsAboutUsPage.secondaryVideoTitle}
					videoLength={data.datoCmsAboutUsPage.secondaryVideoLength}
					responsive
				/>
			) : null}
			{data.datoCmsAboutUsPage.twoColumnTextDisplayOnPage && (
				<TwoColText
					drawerRef={adviceFormRef}
					textColTextContent={twoColumnText}
				/>
			)}
			{ppcHeaderIndex === -1 && (
				<BreadCrumbs breadCrumbItems={breadCrumbItems} />
			)}

			{/* {locale !== 'en-CA' ? <FeefoTestimonials locale={locale} /> : null} */}

			<AdviceBanner
				drawerRef={adviceFormRef}
				adviceBannerContent={adviceBannerContent}
			/>
			<TrustpilotReviews
				pageName="homepage"
				showRatings
				reviews={data.allDatoCmsTrustpilotReview.nodes}
			/>
			{locale !== 'en-CA' ? (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceForm
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessForm
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			) : (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceFormCA
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessFormCA
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			)}
		</GoogleReCaptchaProvider>
	);
};

const WrappedAboutUs = ({ location, data }) => {
	const { locale } = data.datoCmsAboutUsPage;
	const [categoryParam, setCategoryParam] = useState(null);
	const [ppcHeaderIndex, setPpcHeaderIndex] = useState(-1);

	// Check if the code is running in the browser
	useEffect(() => {
		if (typeof window !== 'undefined') {
			const urlParams = new URLSearchParams(window.location.search);
			const category = urlParams.get('category');
			if (category) {
				// Clean category value by removing slashes
				setCategoryParam(category.replace(/^\/+|\/+$/g, ''));
			}
		}
	}, []);

	// Update ppcHeaderIndex when categoryParam changes
	useEffect(() => {
		if (categoryParam) {
			const ppcHeaderArr = data?.allDatoCmsPpcPage?.nodes || [];
			const index = ppcHeaderArr.findIndex(
				(elm) => elm?.siteNavigation[0]?.category === categoryParam
			);
			setPpcHeaderIndex(index);
		}
	}, [categoryParam, data?.allDatoCmsPpcPage?.nodes]);

	// Conditional rendering based on locale and ppcHeaderIndex
	return locale === 'en-CA' && ppcHeaderIndex !== -1 ? (
		<DefaultLayoutPpcV2
			siteSettings={data.siteSettings}
			navigation={
				data?.allDatoCmsPpcPage?.nodes[ppcHeaderIndex]
					?.siteNavigation[0]
			}
			footerData={data.footerItems}
			pageName="PeninsulaNow"
		>
			<AboutUsPage location={location} data={data} />
		</DefaultLayoutPpcV2>
	) : (
		<DefaultLayout
			siteSettings={data.siteSettings}
			navigation={data.navItems}
			footerData={data.footerItems}
			pageName="PeninsulaNow"
		>
			<AboutUsPage location={location} data={data} />
		</DefaultLayout>
	);
};

export default WrappedAboutUs;

export const query = graphql`
	query aboutUs($locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		datoCmsTrustpilotRating(locale: { eq: $locale }) {
			id
			trustscore {
				id
				originalId
				stars
				trustscore
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		allDatoCmsTrustpilotReview(
			sort: { order: ASC, fields: rating }
			filter: { source: { ne: "trustpilot" }, locale: { eq: $locale } }
		) {
			nodes {
				id
				isVerified
				locale
				originalId
				pageTag
				source
				personName
				content {
					value
				}
				rating
				title
				date(fromNow: true)
			}
		}
		datoCmsAboutUsPage(locale: { eq: $locale }) {
			originalId
			locale
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			internal {
				type
			}
			heading
			subHeading
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			canonicalUrl
			iconBanner {
				id
				heading
				subHeading
				icon
			}
			textWithImageContainer {
				id
				title {
					value
				}
				firstContent {
					value
				}
				firstImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				secondContent {
					value
				}
				secondImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				buttonCopy
				buttonLink
			}
			primaryVideo {
				id: providerUid
				url
			}
			primaryVideoTitle
			primaryVideoLength
			uspContainer {
				title {
					value
				}
				subText
				uspCards {
					title
					content
					linkCopy
					link
					icon
				}
			}
			testimonials {
				id
				title {
					value
				}
				subText
				slides {
					id
					image {
						alt
						gatsbyImageData(
							aspectRatio: 45
							placeholder: DOMINANT_COLOR
							width: 400
							sizes: "(max-width: 640px) 400px, 800px"
							height: 500
						)
					}
					quote
					nameAndJob
					company
					tags
				}
				primaryLinkCopy
				secondaryLinkCopy
				secondaryLink
			}
			secondaryVideo {
				id: providerUid
				url
			}
			secondaryVideoTitle
			secondaryVideoLength
			secondaryVideoDisplayOnPage
			twoColumnText {
				id
				title {
					value
				}
				content {
					value
				}
				primaryLinkCopy
				primaryLink
				primaryButtonOpenForm
				secondaryLinkCopy
				secondaryLink
			}
			twoColumnTextDisplayOnPage
			adviceBanner {
				title {
					value
				}
				content
				ctaLabel
				ctaLink
				ctaOpenForm
			}
		}
		allDatoCmsPpcPage(filter: { locale: { eq: $locale } }) {
			nodes {
				siteNavigation {
					title
					category
					homeLink
					headerNavigationItems {
						title
						link
						treeChildren {
							title
							link
							position
							icon
						}
					}
					footerNavigationItems {
						title
						link
					}
				}
			}
		}
	}
`;
