/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { createHeadingHighlightCA } from '../../../utils/heading-highlight-ca';
import openQuote from './quotes/open_quote.svg';
import closeQuote from './quotes/close_quote.svg';
import { useModal } from '../../../contexts/modal-context';
import { Button } from '../../atoms/button-ca';
import { useDrawer } from '../../../contexts/drawer-context';
import { testimonialsDefaultContent } from './data';

const TestimonialsPpcV2CA = ({
	testimonialsPpcV2CA = {},
	modalRef,
	drawerRef,
	className = '',
	firstChildClassName = '',
	buttonVariant = 'Red',
	titleVariant = 'red',
}) => {
	const { image, subText, testimonialCards, primaryLinkCopy } =
		testimonialsPpcV2CA;
	const { setModalRef } = useModal();
	const { setDrawerRef } = useDrawer();

	// State to manage visibility of cards
	const [showAll, setShowAll] = useState(false);

	// Determine the number of cards to show
	const cardsToShow =
		showAll &&
		Array.isArray(
			testimonialCards || testimonialsDefaultContent?.testimonialCards
		)
			? testimonialCards || testimonialsDefaultContent?.testimonialCards
			: Array.isArray(
					testimonialCards ||
						testimonialsDefaultContent?.testimonialCards
			  )
			? testimonialCards?.slice(0, 3) ||
			  testimonialsDefaultContent?.testimonialCards?.slice(0, 3)
			: [];

	return (
		<>
			<div className="max-w-m-screen flex items-end justify-end px-5 -mb-[3rem] mt-1 md:mt-8 md:-mb-[8rem] lg:-mb-[10.5rem] lg:mr-[2.5rem] lg:-mt-7 xl:-mb-[12rem] xl:mr-[11rem] xl:-mt-10 2xl:mx-auto 2xl:pr-[14.5rem]">
				<GatsbyImage
					image={getImage(image || testimonialsDefaultContent?.image)}
					loading="lazy"
					className="max-w-[61px] md:max-w-[92px] lg:max-w-[196px] xl:mb-[1.5rem]"
				/>
			</div>
			<div className={`w-full bg-[#EDF4FF] ${className}`}>
				<div
					className={`flex flex-col items-center px-6 pb-0 pt-8 lg:px-10 lg:pt-20 lg:pb-16 mx-auto lg:max-w-m-screen bg-[#EDF4FF] ${firstChildClassName}`}
				>
					{/* title - custom title isn't allowed if used with CAN badge */}
					<div className="pb-5 pt-2">
						{createHeadingHighlightCA({
							headings:
								testimonialsDefaultContent?.title?.value
									?.document?.children[0]?.children,
							variant: titleVariant,
							className: `hidden md:block text-center text-3xl md:text-5xl font-castledown-regular leading-[1.2] md:leading-[1.3] max-w-[650px] md:pt-8 xl:pt-0`,
						})}
						{/* for mobile with no line break */}
						{createHeadingHighlightCA({
							headings:
								testimonialsDefaultContent?.titleMobile?.value
									?.document?.children[0]?.children,
							variant: titleVariant,
							className: `block md:hidden text-center text-3xl md:text-5xl font-castledown-regular leading-[1.2] md:leading-[1.3] max-w-[650px] md:pt-8 xl:pt-0`,
						})}
					</div>
					{/* subText */}
					<div className="text-center text-[20px] font-centra-light pb-10 lg:pb-16">
						{subText || testimonialsDefaultContent?.subText}
					</div>
					{/* cards */}
					<div className="grid grid-flow-row lg:grid-flow-row lg:grid-cols-3 gap-5 font-castledown-regular">
						{/* for desktop */}
						{(testimonialCards?.length > 0
							? testimonialCards
							: testimonialsDefaultContent?.testimonialCards
						)?.map((elm) => (
							<div
								key={elm?.id}
								className="col-span-1 h-fit bg-white rounded-[.75rem] border-1 border-[#dddddd] p-5 text-3xl-f text-[16px] leading-[1.35rem] font-centra-no1 relative shadow-sm hidden sm:block"
							>
								<div className="font-centra-book">
									<span className="inline">
										<img
											src={openQuote || ''}
											className="inline pb-1 pr-2 -mt-2"
											alt=""
										/>
										{elm.content || ''}
										<img
											src={closeQuote}
											className="inline pl-2 pb-1"
											alt=""
										/>
									</span>
								</div>
								<div className="flex flex-row justify-between items-center mt-5">
									<div className="flex flex-col font-centra-no1 font-bold">
										<div className="font-castledown-regular text-[#3954E0]">
											{elm?.name || ''},
										</div>
										<div className="text-[#1f237a] max-w-[250px]">
											{elm?.job || ''}
										</div>
									</div>
									<div>
										<GatsbyImage
											image={getImage(elm?.profile)}
											loading="lazy"
											className="min-h-[74px] min-w-[74px]"
										/>
									</div>
								</div>
							</div>
						))}
						{/* for mobile */}
						{cardsToShow.length > 0 &&
							cardsToShow?.map((elm) => (
								<div
									key={elm.id} // Ensure each element has a unique key
									className="col-span-1 h-fit bg-white rounded-[.75rem] border-1 border-[#dddddd] p-5 text-3xl-f text-[16px] leading-[1.35rem] font-centra-no1 relative shadow-sm block sm:hidden"
								>
									<div className="font-centra-book">
										<span className="inline">
											<img
												src={openQuote}
												className="inline pb-1 pr-2 -mt-2"
												alt=""
											/>
											{elm?.content}
											<img
												src={closeQuote}
												className="inline pl-2 pb-1"
												alt=""
											/>
										</span>
									</div>
									<div className="flex flex-row justify-between items-center mt-5">
										<div className="flex flex-col font-centra-no1 font-bold">
											<div className="font-castledown-regular text-[#3954E0]">
												{elm?.name},
											</div>
											<div className="text-[#1f237a] max-w-[250px]">
												{elm?.job}
											</div>
										</div>
										<div>
											<GatsbyImage
												image={getImage(elm?.profile)}
												loading="lazy"
												className="min-h-[74px] min-w-[74px]"
											/>
										</div>
									</div>
								</div>
							))}
					</div>
					{/* Read More Button for mobile */}
					{!showAll &&
						(testimonialCards?.length > 3 ||
							testimonialsDefaultContent?.testimonialCards
								?.length > 3) && (
							<div className="flex justify-center pt-5 lg:pt-16 block sm:hidden">
								<button
									onClick={() => setShowAll(true)}
									className="hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-centra-medium focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 bg-transparent hover:bg-transparent focus:bg-transparent text-blue-400 !border-0 !p-0 underline decoration-1 hover:text-brand-blue-400 focus:shadow-focus focus:outline-focus"
								>
									Read More
								</button>
							</div>
						)}
					{/* CTA */}
					<div className="flex justify-center pb-10 pt-10 lg:pt-16">
						<Button
							onClick={() => {
								if (modalRef !== null) setModalRef(modalRef);
								if (drawerRef !== null) setDrawerRef(drawerRef);
							}}
							className="hidden md:block transition delay-150 duration-1000 mx-auto inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-centra-medium leading-6 whitespace-nowrap"
							variant={buttonVariant}
						>
							{primaryLinkCopy ||
								testimonialsDefaultContent?.primaryLinkCopy}
						</Button>
						{/* for mobile devices */}
						<Button
							onClick={() => {
								if (modalRef !== null)
									window.location.href = '#adviceform';
								if (drawerRef !== null) setDrawerRef(drawerRef);
							}}
							className="block md:hidden transition delay-150 duration-1000 mx-auto inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-centra-medium leading-6 whitespace-nowrap"
							variant={buttonVariant}
						>
							{primaryLinkCopy ||
								testimonialsDefaultContent?.primaryLinkCopy}
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};

export default TestimonialsPpcV2CA;
