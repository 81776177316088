/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import CTAbuttonsCA from '../../atoms/cta-buttons-ca';
import { createHeadingHighlightCA } from '../../../utils/heading-highlight-ca';
import { hrData, hsData } from './data';

const ServicesContainerPpcV2CA = ({ servicesContent, modalRef }) => {
	const {
		category,
		servicesTitle,
		servicesSubtitle,
		servicesList,
		servicesImage,
		servicesCtaLabel,
		servicesBackgroundImage,
		servicesDownloadTitle,
		servicesDownloadList,
		servicesDownloadCtaLabel,
		servicesDownloadCtaLink,
		servicesDownloadImage,
		servicesDownloadDisplayOnPage = true,
	} = servicesContent;

	let servicesDefaultContent;

	if (category === 'hr') {
		servicesDefaultContent = hrData;
	} else if (category === 'hs') {
		servicesDefaultContent = hsData;
	}

	let titleText = '';

	const headings =
		servicesTitle?.value?.document?.children?.[0]?.children ||
		servicesDefaultContent?.servicesTitle?.value?.document?.children?.[0]
			?.children;

	if (headings && headings.length > 0) {
		titleText = createHeadingHighlightCA({
			variant: 'brand-blue-underline',
			headings,
		});
	}

	const servicesContainerBGImage = getImage(
		servicesBackgroundImage ||
			servicesDefaultContent?.servicesBackgroundImage
	);
	const servicesDownloadBGImage = getImage(
		servicesDownloadImage || servicesDefaultContent?.servicesDownloadImage
	);

	return (
		<div
			className="md:m-0 py-12 md:pt-16 lg:pt-24 lg:pb-0 w-full"
			style={{
				backgroundImage: `url(${
					servicesContainerBGImage?.images?.fallback?.src ||
					servicesDefaultContent?.servicesContainerBGImage?.images
						?.fallback?.src
				})`,
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'contain',
				backgroundPosition: 'top left',
			}}
		>
			<div className="flex flex-col gap-6 max-w-m-screen mx-auto -mt-3 px-5 md:px-8 lg:px-10 text-center z-10">
				{/* title */}
				<div className="text-3xl md:text-5xl font-castledown-regular leading-[1.2] md:leading-[1.3]">
					{titleText}
				</div>
				{/* sub-title */}
				<div className="text-xl font-centra-light mb-5">
					{servicesSubtitle ||
						servicesDefaultContent?.servicesSubtitle}
				</div>
				<div className="flex flex-col items-center lg:items-stretch lg:flex-row justify-center gap-5 w-full">
					{/* left panel */}
					<div className="flex flex-col lg:flex-row justify-center w-full lg:w-2/3 border-2 border-[#DDDDDD] rounded-[0.75rem] z-10">
						<div
							className={`w-full lg:w-1/2 rounded-t-[0.75rem] lg:rounded-none lg:rounded-l-[0.75rem] object-fill flex items-center justify-center ${
								category === 'hs' && 'bg-[#D9E0F3]'
							} ${category === 'hr' && 'bg-[#3954E0]'}`}
						>
							<GatsbyImage
								image={getImage(
									servicesImage ||
										servicesDefaultContent?.servicesImage
								)}
								loading="lazy"
								className="object-fill lg:w-full rounded-t-[0.75rem] lg:rounded-none"
							/>
						</div>

						<div className="flex flex-col gap-2 md:gap-4 lg:gap-2 justify-between items-start bg-[#F5F5F7] w-full lg:w-1/2 p-5 rounded-b-[0.75rem] lg:rounded-none lg:rounded-r-[0.75rem]">
							{(servicesList.length > 0
								? servicesList
								: servicesDefaultContent?.servicesList
							).map((item, idx) => (
								<>
									<div className="flex flex-row items-center gap-5 text-left">
										<div className="w-30 h-30">
											<GatsbyImage
												image={getImage(item.image)}
												loading="lazy"
											/>
										</div>
										<div
											className="font-centra-light text-[17px] md:text-xl lg:text-[17px]"
											style={{ lineHeight: '1.25rem' }}
										>
											{item.text}
										</div>
									</div>
									{(servicesList.length > 0
										? servicesList
										: servicesDefaultContent?.servicesList
									).length -
										1 !==
										idx && (
										<hr
											className="w-full"
											style={{
												border: 'none',
												height: '2px',
												backgroundColor: '#DDDDDD',
												minWidth: '100%',
											}}
										/>
									)}
								</>
							))}
							<CTAbuttonsCA
								primaryLinkCopy={
									servicesCtaLabel ||
									servicesDefaultContent?.servicesCtaLabel
								}
								primaryLink={null}
								primaryLinkOpenForm
								secondaryLinkCopy={null}
								secondaryLink={null}
								secondaryLinkOpenForm={null}
								modalRef={modalRef}
								useModalForm
								drawerRef={null}
								variant="col"
								buttonVariant="Red"
								className="-mb-2"
							/>
						</div>
					</div>
					{/* right panel - download section - optional */}
					{servicesDownloadDisplayOnPage && (
						<div className="flex flex-col gap-5 justify-end items-start bg-[#EDF4FF] rounded-[0.75rem] p-5 w-full lg:w-1/3 border-2 border-[#E2EBF9] rounded-[0.75rem]">
							<div
								className="flex self-end lg:mb-20 xl:mb-14"
								style={{
									backgroundImage: `url(${
										servicesDownloadBGImage?.images
											?.fallback?.src ||
										servicesDefaultContent
											?.servicesDownloadBGImage?.images
											?.fallback?.src
									})`,
									backgroundRepeat: 'no-repeat',
									backgroundSize: 'contain',
									backgroundPosition: 'top right',
									minWidth: '129px',
									minHeight: '120px',
								}}
							>
								&nbsp;
							</div>
							{/* Content div */}
							<div className="flex flex-col gap-2 items-start lg:-mt-28">
								<div className="text-left md:w-3/5 font-centra-light text-[16px] z-10">
									{servicesDownloadTitle ||
										servicesDefaultContent?.servicesDownloadTitle}
								</div>
								<div className="font-centra-book text-[27px] text-blue-500 text-left z-10">
									{(servicesDownloadList.length > 0
										? servicesDownloadList
										: servicesDefaultContent?.servicesDownloadList
									).map((item) => (
										<div>{item.text}</div>
									))}
								</div>
								<div>
									<CTAbuttonsCA
										primaryLinkCopy={
											servicesDownloadCtaLabel ||
											servicesDefaultContent?.servicesDownloadCtaLabel
										}
										primaryLink={
											servicesDownloadCtaLink ||
											servicesDefaultContent?.servicesDownloadCtaLink
										}
										primaryLinkOpenForm={false}
										variant="col"
										buttonVariant="RedReverse"
										className="-mb-2"
									/>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export { ServicesContainerPpcV2CA };
