// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
import clsx from 'clsx';
import { PBSLink } from '../link-ca';
import { Button } from '../button-ca';
import { useModal } from '../../../contexts/modal-context';
import { useDrawer } from '../../../contexts/drawer-context';

const CTAbuttonsCA = ({
	primaryLinkCopy,
	primaryLink,
	primaryLinkOpenForm,
	secondaryLinkCopy,
	secondaryLink,
	secondaryLinkOpenForm,
	modalRef,
	useModalForm,
	drawerRef,
	variant,
	buttonVariant,
	className = '',
}) => {
	const { setModalRef } = useModal();
	const { setDrawerRef } = useDrawer();

	function checkLinkType(link, linkCopy, type) {
		if (primaryLinkOpenForm === true && type === 'primary') {
			return useModalForm ? (
				<Button
					onClick={() => {
						setModalRef(modalRef);
					}}
					className="w-max transition delay-150 duration-1000 ease-in-out transition-colors"
					variant={buttonVariant}
				>
					{linkCopy}
				</Button>
			) : (
				<Button
					onClick={() => {
						setDrawerRef(drawerRef);
					}}
					className="w-max transition delay-150 duration-1000 ease-in-out transition-colors"
					variant={buttonVariant}
				>
					{linkCopy}
				</Button>
			);
			// Handle other types of links
		}
		if (secondaryLinkOpenForm === true && type === 'secondary') {
			return useModalForm ? (
				<Button
					onClick={() => {
						setModalRef(modalRef);
					}}
					className="w-max transition delay-150 duration-1000 ease-in-out transition-colors"
					variant={buttonVariant}
				>
					{linkCopy}
				</Button>
			) : (
				<Button
					onClick={() => {
						setDrawerRef(drawerRef);
					}}
					className="w-max transition delay-150 duration-1000 ease-in-out transition-colors"
					variant={buttonVariant}
				>
					{linkCopy}
				</Button>
			);
			// Handle other types of links
		}
		if (link?.startsWith('tel:')) {
			return (
				<a
					href={link}
					className={`inline-flex items-center transition delay-150 duration-1000 ease-in-out transition-colors px-6 py-4 text-lg leading-6 text-white transition-colors border-2 rounded-lg md:mb-0 hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 md:px-8 md:text-xl font-centra-medium focus:outline-0 focus:shadow-focus w-max ${
						buttonVariant === 'Red'
							? 'bg-[#E30138] border-[#E30138]'
							: 'bg-blue-400 border-blue-400'
					}`}
				>
					<span className="rulerclick">{linkCopy}</span>
				</a>
			);
			// Handle telephone link
		}
		if (link?.startsWith('/')) {
			return (
				<PBSLink
					className={clsx(
						'justify-center w-full transition delay-150 duration-1000 ease-in-out transition-colors sm:w-max hover:text-white',
						{
							'bg-blue-400 text-white':
								type === 'primary' &&
								buttonVariant !== 'Red' &&
								buttonVariant !== 'RedReverse',
						},
						{
							'bg-[#E30138] border-[#E30138] text-white':
								buttonVariant === 'Red',
						},
						{
							'bg-white border-[#E30138] text-[#E30138]':
								buttonVariant === 'RedReverse',
						}
					)}
					to={link}
					variant={buttonVariant}
				>
					{linkCopy}
				</PBSLink>
			);
			// Handle internal link
		}
	}

	return (
		<div
			className={clsx(
				`flex flex-row gap-5 mt-3 lg:mx-0 ${
					variant === 'col'
						? 'flex flex-col items-center lg:flex-col md:items-start'
						: 'lg:flex-row items-center mx-auto'
				} ${className}`
			)}
		>
			<div className="rounded-xl">
				{checkLinkType(
					primaryLink,
					primaryLinkCopy,
					'primary',
					primaryLinkOpenForm
				)}
			</div>
			<div className="rounded-xl">
				{checkLinkType(secondaryLink, secondaryLinkCopy, 'secondary')}
			</div>
		</div>
	);
};

export default CTAbuttonsCA;
