/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { DefaultLayout } from '../layouts/default/ppc-v2-new';
import { Head } from '../components/templates/head';
// for modal
import { AdviceShortFormCA } from '../components/organisms/ca/forms/salesforce/advice-short';
import { SuccessModal } from '../components/organisms/ca/forms/salesforce/success-modal';

// import { IconCardsPpcV2CA } from '../components/organisms/icon-cards-ppc-v2-ca';
import { HeroPpcV2CA } from '../components/molecules/hero-ppc-v2-ca';
import { TextWithImageContainer } from '../components/molecules/text-with-image-container-ppc-v2-ca';
import { ServicesContainerPpcV2CA } from '../components/molecules/services-container-ppc-v2-ca';
import { pageTypes } from '../utils/url-helpers';
import { Modal } from '../components/atoms/modal-v2-ca';
import { ClientLogosCA } from '../components/atoms/client-logos-ca';
import {
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
} from '../utils/seo-schema';
import { useModal } from '../contexts/modal-context';
import { HrefLang } from '../components/templates/hrefLang';
import { CallUs } from '../components/atoms/call-us-ppc-v3-ca';
import CtaBannerPPCV2 from '../components/atoms/cta-banner-ppc-v2';
import { CorCA } from '../components/organisms/cor-ca';
import { HeroVideoPpcV2CA } from '../components/molecules/hero-video-ppc-v2-ca';
import { isVideo } from '../utils/is-video';
import TestimonialsPpcV2CA from '../components/organisms/testimonials-ppc-v2-ca';
import { CorV2CA } from '../components/organisms/cor-ca-v2';

// markup
const PpcV2CAPage = ({ data, location }) => {
	const { modalRef, setModalRef } = useModal();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');

	const metaContent = {
		noIndex: data.datoCmsPpcPage.noIndex,
		noFollow: data.datoCmsPpcPage.noFollow,
		metaInformation: data.datoCmsPpcPage.metaInformation,
		fallbackTitle: data.datoCmsPpcPage.title,
		fallbackDescription: data.datoCmsPpcPage.longIntroduction,
		canonicalUrl: data.datoCmsPpcPage?.canonicalUrl || location.href,
	};

	const servicesContent = {
		category: data?.datoCmsPpcPage?.siteNavigation[0]?.category || '',
		servicesTitle: data.datoCmsPpcPage?.servicesTitle,
		servicesSubtitle: data.datoCmsPpcPage?.servicesSubtitle,
		servicesList: data.datoCmsPpcPage?.servicesList,
		servicesImage: data.datoCmsPpcPage?.servicesImage,
		servicesCtaLabel: data.datoCmsPpcPage?.servicesCtaLabel,
		servicesBackgroundImage: data.datoCmsPpcPage?.servicesBackgroundImage,
		servicesDownloadTitle: data.datoCmsPpcPage?.servicesDownloadTitle,
		servicesDownloadList: data.datoCmsPpcPage?.servicesDownloadList,
		servicesDownloadCtaLabel: data.datoCmsPpcPage?.servicesDownloadCtaLabel,
		servicesDownloadCtaLink: data.datoCmsPpcPage?.servicesDownloadCtaLink,
		servicesDownloadImage: data.datoCmsPpcPage?.servicesDownloadImage,
		servicesDownloadDisplayOnPage:
			data.datoCmsPpcPage?.servicesDownloadDisplayOnPage,
	};

	const corContent = {
		title: data.datoCmsPpcPage?.corContainer?.title,
		subText: data.datoCmsPpcPage?.corContainer?.subText,
		corCards: data.datoCmsPpcPage?.corContainer?.corCards,
	};

	const textWithImageContainer = {
		...data.datoCmsPpcPage.textWithImageContainer,
		title: data.datoCmsPpcPage.textWithImageContainer?.title,
		firsttitle:
			data.datoCmsPpcPage.textWithImageContainer?.firstTitle?.value
				.document.children,
		secondtitle:
			data.datoCmsPpcPage.textWithImageContainer?.secondTitle.value
				.document.children,
		thirdtitle:
			data.datoCmsPpcPage.textWithImageContainer?.thirdTitle.value
				.document.children,
		firstContent: data.datoCmsPpcPage.textWithImageContainer?.firstContent,
		secondContent:
			data.datoCmsPpcPage.textWithImageContainer?.secondContent,
		thirdContent: data.datoCmsPpcPage.textWithImageContainer?.thirdContent,
	};

	const headerCTAContent = {
		...data.datoCmsPpcPage.mainHeader,
		header: data.datoCmsPpcPage.mainHeader.header.value.document.children,
		secondaryHeaderRows: [...data.datoCmsPpcPage.secondaryHeaderRows],
		googleReview: data.siteSettings.googleReview,
	};

	const headerCTAContent2 = {
		primaryHeaderText: data.datoCmsPpcPage?.mainHeader?.header,
		secondaryHeaderRows: data.datoCmsPpcPage?.secondaryHeaderRows,
		primaryLinkCopy: data.datoCmsPpcPage?.mainHeader?.primaryLinkCopy,
		primaryLink: data.datoCmsPpcPage?.mainHeader?.primaryLink,
		primaryLinkOpenForm:
			data.datoCmsPpcPage?.mainHeader?.primaryLinkOpenForm,
		useModalForm: data.datoCmsPpcPage?.mainHeader?.primaryLinkOpenForm,
		secondaryLinkCopy: null,
		secondaryLink: null,
		secondaryLinkOpenForm: null,
		media: [
			{
				media: {
					url: data.datoCmsPpcPage?.mainHeader?.primaryImage?.url,
				},
			},
			{
				media: {
					url: '',
				},
			},
		],
		secondaryHeaderText:
			data.datoCmsPpcPage?.secondaryHeaderRows.length === 1
				? data.datoCmsPpcPage?.secondaryHeaderRows[0].secondaryHeaderRow
				: data.datoCmsPpcPage?.mainHeader?.secondaryHeader,
		primaryVideo: {
			url: data.datoCmsPpcPage?.mainHeader?.primaryImage?.url,
		},
		primaryVideoPoster: {
			url: data.datoCmsPpcPage?.mainHeader?.secondaryImage?.url,
		},
		googleReview: data.siteSettings.googleReview,
	};

	const primraryImg = data.datoCmsPpcPage?.mainHeader?.primaryImage?.url;
	const hasVideo = isVideo(primraryImg);
	// const uspBanner = data.datoCmsPpcPage.uspCtaCardsBanner;
	// const uspBannerDisplayLinks =
	// 	data.datoCmsPpcPage.uspCtaCardsBannerDisplayLinks;

	const testimonialsContent = {
		image: data.datoCmsPpcPage?.testimonialsCa?.image,
		title: data.datoCmsPpcPage?.testimonialsCa?.title,
		subText: data.datoCmsPpcPage?.testimonialsCa?.subText,
		primaryLinkCopy: data.datoCmsPpcPage?.testimonialsCa?.primaryLinkCopy,
		testimonialCards: data.datoCmsPpcPage?.testimonialsCa?.testimonialCards,
	};

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.service,
			location,
			data: data.datoCmsPpcPage,
		}),
		image: getImageSeoSchema(data.datoCmsPpcPage?.mainHeader?.primaryImage),
		// faq: getFAQSeoSchema(faqContent?.questions),
	};

	const customFormField = {
		customFormTitle:
			data.datoCmsPpcPage?.customFormField[0]?.customFormTitle,
		customFormSubtitle:
			data.datoCmsPpcPage?.customFormField[0]?.customFormSubtitle,
		customFormButton:
			data.datoCmsPpcPage?.customFormField[0]?.customFormButton,
	};

	// for CTA banner
	const ctaBannerContent = {
		bannerImage: data.datoCmsPpcPage?.bannerImage,
		bannerTitle: data.datoCmsPpcPage?.bannerTitle,
		bannerContent: data.datoCmsPpcPage?.bannerContent,
		ctaLabel: data.datoCmsPpcPage?.ctaLabel,
	};

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<div className="block sm:hidden">
				<CallUs />
			</div>
			<HrefLang locales={data.datoCmsPpcPage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />

			{/* hero wrapper div */}
			<div className="lg:-mt-10">
				{hasVideo ? (
					<HeroVideoPpcV2CA
						{...headerCTAContent2}
						heroVideoCampaignCAContent={headerCTAContent2}
						location={location}
						modalRef={adviceFormRef}
						drawerRef={adviceFormRef}
						className="lg:-mt-16 xl:-mt-5 2xl:mt-0 2xl:pt-1 "
						firstChildClassName="lg:-mb-20 xl:-mb-11 xl:-mb-[4rem] 2xl:-mb-[6rem]"
						titleClassName=""
						subTitleClassName="text-[#1D2951] xl:text-[24px]"
					/>
				) : (
					<HeroPpcV2CA
						modalRef={adviceFormRef}
						{...headerCTAContent}
						ctaLabel={ctaBannerContent.ctaLabel}
					/>
				)}
			</div>

			{/* Not needed on the COR pg */}
			{!data.datoCmsPpcPage?.corContainerDisplayOnPage &&
				data.datoCmsPpcPage.servicesDisplayOnPage && (
					<ServicesContainerPpcV2CA
						servicesContent={servicesContent}
						modalRef={adviceFormRef}
					/>
				)}

			{data.datoCmsPpcPage?.corContainerDisplayOnPage && (
				<div className="xl:-mb-44 xl:-mt-10">
					<CorV2CA
						modalRef={adviceFormRef}
						corContent={corContent}
						isPPC
					/>
				</div>
			)}

			{/* For larger screens or desktop */}
			{data.datoCmsPpcPage.clientLogosBannerDisplayOnPage && (
				<ClientLogosCA
					pathname={location.pathname}
					className="-mt-16 border-b-2 border-[#EDEDF3] max-w-m-screen mx-auto px-10 md:pt-10 lg:pt-20"
					logoClassName="-mb-11"
					logoCopyClassName="text-[20px] font-light text-[#000]"
				/>
			)}
			{/* For mobile devices */}
			<section
				id="adviceform"
				className="md:hidden lg:hidden xl:hidden border-blue-300 border-2 focus-within:outline-blue-400/[0.20] rounded-sm p-1 mx-4 mb-12 scroll-mt-[175x] md:scroll-mt-[160px] scroll-mt-[140px]"
			>
				{reference === '' ? (
					<AdviceShortFormCA
						setReference={setReference}
						customFormField={customFormField}
						isMobile
						buttonVariant="Red"
					/>
				) : (
					<SuccessModal />
				)}
			</section>
			{/* For mobile devices */}
			{data.datoCmsPpcPage.clientLogosBannerDisplayOnPage && (
				<ClientLogosCA
					pathname={location.pathname}
					isMobile
					className="-mb-10 "
				/>
			)}
			{/* {data.datoCmsPpcPage.uspCtaCardsBannerDisplayOnPage && (
				<>
					<h2 className="mx-1 mt-16 mb-12 text-4xl text-center lg:mr-0 lg:text-4xl font-castledown-regular">
						<span>Here&apos;s how we can support</span>
						<span className="text-brand-red-400 pb-1 bg-no-repeat bg-underline bg-bottom bg-[length:100%_6px] md:bg-[length:100%_8px] index-module--highlight--cabc4">
							{' '}
							your{' '}
						</span>
						<span>business</span>
					</h2>
					<IconCardsPpcV2CA
						iconCardsContent={{
							uspCards: uspBanner,
						}}
						showContentLinks={uspBannerDisplayLinks}
					/>
				</>
			)} */}

			{data.datoCmsPpcPage.textWithImageContainerDisplayOnPage && (
				<TextWithImageContainer
					textWithImageContainerContent={textWithImageContainer}
					modalRef={adviceFormRef}
				/>
			)}
			{data.datoCmsPpcPage?.testimonialsDisplayOnPage && (
				<TestimonialsPpcV2CA
					testimonialsPpcV2CA={testimonialsContent}
					modalRef={adviceFormRef}
					className="bg-[#F5F5F7]"
					firstChildClassName="bg-[#F5F5F7] md:pb-8 lg:pb-8 lg:pt-7 xl:pt-12 xl:pb-7"
					buttonVariant="Red"
					titleVariant="brand-blue-underline"
					hasTitleImage
				/>
			)}
			{data.datoCmsPpcPage?.bannerDisplayOnPage && (
				<CtaBannerPPCV2
					ctaBannerContent={ctaBannerContent}
					modalRef={adviceFormRef}
				/>
			)}
			{/* For larger screens or deskop */}
			<Modal
				label="Book my free advice call"
				ref={adviceFormRef}
				open={modalRef === adviceFormRef}
				hasClose
				className="!p-0 z-50"
				elevation={20}
			>
				{reference === '' ? (
					<AdviceShortFormCA
						setReference={setReference}
						customFormField={customFormField}
						buttonVariant="Red"
					/>
				) : (
					<SuccessModal className="my-[15%]" />
				)}
			</Modal>
		</GoogleReCaptchaProvider>
	);
};

export const query = graphql`
	query service($slug: String!, $locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
			googleReview {
				url
				alt
			}
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsPpcPage(slug: { eq: $slug }, locale: { eq: $locale }) {
			originalId
			locale
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			slug
			title
			intro
			uspTitle
			siteNavigation {
				title
				homeLink
				category
				headerNavigationItems {
					title
					link
					treeChildren {
						title
						link
						position
						icon
					}
				}
				footerNavigationItems {
					title
					link
				}
			}
			clientLogosBannerDisplayOnPage
			feefoTestimonialsBannerDisplayOnPage
			testimonialsDisplayOnPage
			mainHeaderDisplayOnPage
			uspCtaCardsBannerDisplayOnPage
			uspCtaCardsBannerDisplayLinks
			textWithImageContainerDisplayOnPage
			corContainerDisplayOnPage
			bannerDisplayOnPage
			mainHeader {
				header {
					value
				}
				secondaryHeader
				primaryLinkCopy
				primaryLink
				primaryLinkOpenForm
				secondaryLinkCopy
				secondaryLink
				showSecondButton
				primaryImage {
					alt
					url
					gatsbyImageData(
						imgixParams: {
							auto: "format, compress"
							sharp: 12
							lossless: true
							q: 85
							dpr: 2
							fm: "webp"
						}
						placeholder: BLURRED
						sizes: "(min-width: 1024px) 612px, (min-width: 768px) 50vw, (min-width: 375px) 100vw"
					)
				}
				secondaryImage {
					alt
					url
					gatsbyImageData(
						width: 380
						height: 330
						layout: CONSTRAINED
					)
				}
				showTrustPilot
			}

			secondaryHeaderRows {
				secondaryHeaderRow
			}

			uspCtaCardsBanner {
				title
				content
				linkCopy
				link
				icon
			}
			corContainer {
				title {
					value
				}
				subText
				corCards {
					title
					subText
					buttonCopy
					items {
						text
					}
				}
			}
			textWithImageContainer {
				id
				title {
					value
				}
				firstTitle {
					value
				}
				firstContent {
					value
				}
				firstImage {
					alt
					url
					gatsbyImageData(
						imgixParams: {
							auto: "format, compresss"
							q: 85
							dpr: 2
							fm: "webp"
						}
						placeholder: BLURRED
					)
				}
				firstButtonCopy
				firstDisplayCtaOnPage
				secondTitle {
					value
				}
				secondContent {
					value
				}
				secondImage {
					alt
					url
					gatsbyImageData(
						imgixParams: {
							auto: "format, compresss"
							q: 85
							dpr: 2
							fm: "webp"
						}
						placeholder: BLURRED
					)
				}
				secondButtonCopy
				secondDisplayCtaOnPage
				thirdTitle {
					value
				}
				thirdContent {
					value
				}
				thirdImage {
					alt
					url
					gatsbyImageData(
						imgixParams: {
							auto: "format, compresss"
							q: 85
							dpr: 2
							fm: "webp"
						}
						placeholder: BLURRED
					)
				}
				thirdButtonCopy
				thirdDisplayCtaOnPage
			}
			testimonialsCa {
				image {
					gatsbyImageData(
						imgixParams: {
							auto: "format, compresss"
							q: 85
							dpr: 2
							fm: "webp"
						}
						placeholder: BLURRED
					)
				}
				title {
					value
				}
				subText
				testimonialCards {
					content
					name
					job
					profile {
						gatsbyImageData(
							imgixParams: {
								auto: "format, compresss, enhance"
								q: 85
								dpr: 2
								fm: "webp"
								w: "74px"
								h: "74px"
							}
							placeholder: BLURRED
						)
					}
				}
				primaryLinkCopy
			}
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			canonicalUrl
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			customFormField {
				customFormTitle
				customFormSubtitle
				customFormButton
			}
			bannerImage {
				gatsbyImageData(
					imgixParams: {
						auto: "format, compresss, enhance"
						q: 85
						dpr: 2
						fm: "webp"
					}
					placeholder: BLURRED
				)
			}
			bannerContent
			bannerHeading {
				value
			}
			ctaLabel
			servicesTitle {
				value
			}
			servicesSubtitle
			servicesList {
				text
				image {
					gatsbyImageData(
						imgixParams: {
							auto: "format, compresss"
							q: 85
							dpr: 2
							fm: "webp"
						}
						placeholder: BLURRED
					)
				}
			}
			servicesImage {
				gatsbyImageData(
					imgixParams: {
						auto: "format, compresss"
						q: 85
						dpr: 2
						fm: "webp"
					}
					placeholder: BLURRED
				)
			}
			servicesCtaLabel
			servicesBackgroundImage {
				gatsbyImageData(
					imgixParams: {
						auto: "format, compresss"
						q: 85
						dpr: 2
						fm: "webp"
					}
					placeholder: BLURRED
				)
			}
			servicesDownloadTitle
			servicesDownloadList {
				text
			}
			servicesDownloadCtaLabel
			servicesDownloadCtaLink
			servicesDownloadImage {
				gatsbyImageData(
					imgixParams: {
						auto: "format, compresss"
						q: 85
						dpr: 2
						fm: "webp"
					}
					placeholder: BLURRED
				)
			}
			servicesDownloadDisplayOnPage
			servicesDisplayOnPage
		}
		datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			logo {
				url
			}
			footerImageCa {
				url
				alt
			}
			footerImage1 {
				url
				alt
			}
			footerImage2 {
				url
				alt
			}
			footerImage3 {
				url
				alt
			}
			footerImage4 {
				url
				alt
			}
			footerImage5 {
				url
				alt
			}
		}
	}
`;

const WrappedPpcV2CA = ({ data, location }) => {
	const footerImages = {
		footerImageCA: data.datoCmsSiteSpecificSetting.footerImageCa,
		footerImage1: data.datoCmsSiteSpecificSetting.footerImage1,
		footerImage2: data.datoCmsSiteSpecificSetting.footerImage2,
		footerImage3: data.datoCmsSiteSpecificSetting.footerImage3,
		footerImage4: data.datoCmsSiteSpecificSetting.footerImage4,
		footerImage5: data.datoCmsSiteSpecificSetting.footerImage5,
	};
	return (
		<DefaultLayout
			siteSettings={data.siteSettings}
			navigation={data?.datoCmsPpcPage?.siteNavigation[0]}
			footerData={data.footerItems}
			footerImages={footerImages}
		>
			<PpcV2CAPage data={data} location={location} />
		</DefaultLayout>
	);
};

export default WrappedPpcV2CA;
