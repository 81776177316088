// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import clsx from 'clsx';

import { DefaultLayout } from '../layouts/default';

import { Head } from '../components/templates/head';

import { AdviceForm } from '../components/organisms/advice-form';
import { AdviceFormCA } from '../components/organisms/ca/forms/salesforce/advice';
import { SuccessForm } from '../components/organisms/success-form';
import { SuccessFormCA } from '../components/organisms/ca/forms/salesforce/success';
import { FAQ } from '../components/organisms/faq';
import { AdviceBanner } from '../components/organisms/advice-banner';
import { CardContainer } from '../components/organisms/card-container';
import { RteContent } from '../components/organisms/rte-content';
import { RteContentCan } from '../components/organisms/rte-content-can';

import { ResourceHeader } from '../components/molecules/resource-header';
import { JumpTo } from '../components/molecules/jump-to';

import { Drawer } from '../components/atoms/drawer';
import { BreadCrumbs } from '../components/atoms/breadcrumbs';
import { LongIntro } from '../components/atoms/long-intro';

import {
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
	getBlogsSchema,
} from '../utils/seo-schema';
import { getBreadcrumbs, pageTypes } from '../utils/url-helpers';
import { useDrawer } from '../contexts/drawer-context';
import { HrefLang } from '../components/templates/hrefLang';

// Markup
const BlogPage = ({ data, location, parentPageData }) => {
	const { drawerRef } = useDrawer();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');

	const metaContent = {
		noIndex: data.datoCmsBlogPage.noIndex,
		noFollow: data.datoCmsBlogPage.noFollow,
		metaInformation: data.datoCmsBlogPage.metaInformation,
		fallbackTitle: data.datoCmsBlogPage.title,
		fallbackDescription: data.datoCmsBlogPage.longIntroduction,
		canonicalUrl: data.datoCmsBlogPage?.canonicalUrl || location.href,
		hreflangs: {
			uk: data.datoCmsBlogPage.hreflangEnGb,
			ie: data.datoCmsBlogPage.hreflangEnIe,
		},
	};

	const resourceHeaderContent = {
		id: data.datoCmsBlogPage.originalId,
		title: data.datoCmsBlogPage.title,
		categories: data.datoCmsBlogPage.categories,
		author: data.datoCmsBlogPage.author,
		createdDate: data.datoCmsBlogPage.created,
		createdDateOrdinal: data.datoCmsBlogPage.createdOrdinal,
		lastUpdated: data.datoCmsBlogPage.meta.updatedAt,
		lastUpdatedOrdinal: data.datoCmsBlogPage.meta.updatedAtOrdinal,
		image: data.datoCmsBlogPage.thumbnail,
		pageUrl: location.href,
	};

	const rteContent = {
		...data.datoCmsBlogPage.content,
	};

	const { locale } = data.datoCmsBlogPage.allSeoLocales[0];

	const faqContent = {
		title: data.datoCmsBlogPage.faqContainer?.title,
		subText: data.datoCmsBlogPage.faqContainer?.subText,
		questions: data.datoCmsBlogPage.faqContainer?.questions.map(
			({ question, answer }) => ({
				question,
				answer: answer.value.document.children,
			})
		),
	};

	const adviceBannerContent = {
		...data.datoCmsBlogPage.adviceBanner,
		title: data.datoCmsBlogPage.adviceBanner?.title.value.document.children,
		ctaLink: data.datoCmsBlogPage.adviceBanner?.ctaLink,
	};

	const blogContainerContent = {
		totalCount: data.allDatoCmsBlogPage.totalCount,
		cards: data.allDatoCmsBlogPage.nodes,
	};

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.blog,
			location,
			data: data.datoCmsBlogPage,
			parentPageData,
		}),
		image: getImageSeoSchema(
			data.datoCmsBlogPage?.mainHeader?.primaryImage
		),
		blog: getBlogsSchema(data.datoCmsBlogPage),
	};

	const displayFaq =
		data.datoCmsBlogPage.faqContainer &&
		data.datoCmsBlogPage.faqContainerDisplayOnPage;

	const lastHubPage = location.pathname.substring(
		0,
		location.pathname.lastIndexOf('/')
	);

	const breadCrumbItems = getBreadcrumbs(
		data.datoCmsBlogPage.internal.type,
		data.datoCmsBlogPage,
		parentPageData
	);

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsBlogPage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<BreadCrumbs breadCrumbItems={breadCrumbItems} />
			<ResourceHeader content={resourceHeaderContent} />
			{data.datoCmsBlogPage.longIntroduction ? (
				<LongIntro content={data.datoCmsBlogPage.longIntroduction} />
			) : null}

			{/* {data.datoCmsBlogPage.showJumpSection ? (
				<>
					{locale === 'en-CA' ? (
						<JumpToCA content={rteContent} />
					) : (
						<JumpTo content={rteContent} />
					)}
				</>
			) : null} */}

			{data.datoCmsBlogPage.showJumpSection ? (
				<JumpTo content={rteContent} />
			) : null}

			{locale === 'en-CA' ? (
				<>
					<RteContentCan
						containerClassName="max-w-content mx-auto mb-14 md:mb-20 px-3 md:px-8 xl:px-0"
						content={rteContent}
						drawerRef={adviceFormRef}
						author={resourceHeaderContent.author}
					/>
				</>
			) : (
				<>
					<RteContent
						containerClassName="max-w-content mx-auto mb-14 md:mb-20 px-3 md:px-8 xl:px-0"
						content={rteContent}
						drawerRef={adviceFormRef}
					/>
				</>
			)}
			<BreadCrumbs
				breadCrumbItems={breadCrumbItems}
				className={clsx(
					displayFaq && '!pb-0',
					!displayFaq && 'pb-14  mb-xl-f md:mb-3xl-f'
				)}
			/>
			{displayFaq ? <FAQ faqContent={faqContent} /> : null}
			{blogContainerContent.totalCount > 0 && (
				<CardContainer
					cardContainerContent={{
						...blogContainerContent,
						id: 'blog-container',
						title: 'Related articles',
						to: `${lastHubPage}`,
						toAll: '/resource-hub',
						buttonCopy: 'Back to resource hub',
					}}
					variant="Center"
					cardVariant="Blog"
				/>
			)}
			{data.datoCmsBlogPage.adviceBanner &&
			data.datoCmsBlogPage.adviceBannerDisplayOnPage ? (
				<AdviceBanner
					drawerRef={adviceFormRef}
					adviceBannerContent={adviceBannerContent}
				/>
			) : null}

			{locale !== 'en-CA' ? (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceForm
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessForm
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			) : (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceFormCA
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessFormCA
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			)}
		</GoogleReCaptchaProvider>
	);
};

export const query = graphql`
	query blogPage(
		$slug: String!
		$relatedBlogCategoryIds: [String!]!
		$locale: String!
	) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsBlogPage(slug: { eq: $slug }, locale: { eq: $locale }) {
			title
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			originalId
			internal {
				type
			}
			slug
			categories {
				name
				slug
			}
			sectors {
				name
			}
			author {
				name
				image {
					alt
					url(imgixParams: { w: "44", h: "44" })
				}
				jobTitle
			}
			thumbnail {
				alt
				url
				gatsbyImageData(
					layout: CONSTRAINED
					aspectRatio: 1.3
					placeholder: DOMINANT_COLOR
					sizes: "(max-width: 640px) 640px, 800px"
				)
			}
			created
			createdOrdinal: created(formatString: "MMM Do YYYY")
			meta {
				updatedAt
				updatedAtOrdinal: updatedAt(formatString: "MMM Do YYYY")
			}
			longIntroduction
			faqContainerDisplayOnPage
			adviceBannerDisplayOnPage
			showJumpSection
			content {
				value
				blocks {
					... on DatoCmsDownloadBlock {
						__typename
						id: originalId
						title
						ctaText
						asset {
							alt
							url
						}
					}
					... on DatoCmsBlogTable {
						__typename
						id: originalId
						tableContent
					}
					... on DatoCmsQuoteBlock {
						__typename
						id: originalId
						quote
						quoteCredit
						model {
							apiKey
						}
					}
					... on DatoCmsVideoBlock {
						__typename
						id: originalId
						videoCaption
						videoLink {
							height
							provider
							providerUid
							thumbnailUrl
							title
							url
							width
						}
						model {
							apiKey
						}
					}
					... on DatoCmsImageBlock {
						__typename
						id: originalId
						hasImageCredit
						imageCredit
						image {
							alt
							url
							width
							height
						}
						model {
							apiKey
						}
					}
					... on DatoCmsMediaTable {
						__typename
						id: originalId
						tableContent
					}
				}
				links {
					... on DatoCmsAdviceBanner {
						__typename
						id: originalId
						title {
							value
						}
						content
						ctaLabel
						ctaLink
						ctaOpenForm
					}
					... on DatoCmsNewsletterSignUp {
						__typename
						id: originalId
						subText
						newsletterSignUpTitle
					}
					... on DatoCmsCtaBanner {
						__typename
						id: originalId
						content
						linkCopy
						link
						ctaBannerTitle
						ctaVersion
						ctaStructuredTextTitle {
							value
						}
						ctaOpenForm
						image {
							alt
							url
							gatsbyImageData(
								width: 415
								height: 315
								layout: CONSTRAINED
							)
						}
					}
					... on DatoCmsBusinessHealthAssistantPromoBanner {
						__typename
						id: originalId
						content
						linkCopy
						promoTitle {
							value
						}
					}
				}
			}
			faqContainer {
				title
				subText
				questions {
					question
					answer {
						value
					}
				}
			}
			adviceBanner {
				title {
					value
				}
				content
				ctaLabel
				ctaLink
				ctaOpenForm
			}
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			canonicalUrl
			hreflangEnGb
			hreflangEnIe
		}
		allDatoCmsBlogPage(
			filter: {
				categories: {
					elemMatch: { id: { in: $relatedBlogCategoryIds } }
				}
				slug: { ne: $slug }
				locale: { eq: $locale }
			}
			sort: { order: DESC, fields: created }
			limit: 3
		) {
			totalCount
			nodes {
				id
				internal {
					type
				}
				publishedDateOrdinal: created(formatString: "MMMM Do YYYY")
				publishedDate: created
				title
				slug
				intro
				thumbnail {
					alt
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 500
						sizes: "(max-width: 640px) 400px, 800px"
						height: 300
					)
				}
				author {
					id
					name
					jobTitle
					image {
						alt
						url(imgixParams: { w: "44", h: "44" })
					}
				}
				categories {
					id
					name
					slug
				}
				sectors {
					id
					name
				}
			}
		}
	}
`;

const WrappedBlog = ({ data, pageContext, location }) => (
	<DefaultLayout
		className="bg-white"
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
		pageName="PeninsulaNow"
	>
		<BlogPage
			data={data}
			location={location}
			slug={pageContext.slug}
			locale={pageContext.locale}
			parentPageData={pageContext.parentPageData}
		/>
	</DefaultLayout>
);

export default WrappedBlog;
