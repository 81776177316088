import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { HeadingTagLine } from '../../atoms/heading-tag-line';

import { titleProp } from '../../../types';

const AdviceBannerCA = ({
	// eslint-disable-next-line react/prop-types
	siteSettings,
	// eslint-disable-next-line react/prop-types
	adviceContent,
	variant,
	className,
}) => {
	// eslint-disable-next-line react/prop-types
	const { title, subText, ctaLabel } = adviceContent;

	return (
		<section
			aria-labelledby="adviceBanner"
			className={clsx(
				'bg-brand-pale-400 xl:pb-5 2xl:pt-4 2xl:pb-8',
				variant === 'Rte'
					? 'border-blue-200 border-2 rounded-sm my-14'
					: 'border-b-4 md:border-b-6 border-b-blue-400',
				className
			)}
		>
			<div
				className={clsx(
					'max-w-[850px] mx-auto',
					variant === 'Rte' ? 'p-8' : 'py-xl-f px-xs-f',
					'text-center'
				)}
			>
				<HeadingTagLine
					id="adviceBanner"
					// eslint-disable-next-line react/prop-types
					title={title.value.document.children}
					subText=""
					headingClassNames={
						variant === 'Rte' ? '!text-2xl md:!text-3xl' : null
					}
					subTextClassNames={
						variant === 'Rte'
							? '!text-lg md:!text-xl !font-centra-book'
							: null
					}
				/>
				{
					// eslint-disable-next-line react/prop-types
					subText.map((elm) => (
						<p className=" lg:mx-0 xl:-mx-36 mt-6 text-xl md:text-2xl font-centra-regular">
							{elm.text}
						</p>
					))
				}

				<div className="flex flex-col items-center justify-center md:flex-row mt-10">
					<a
						// eslint-disable-next-line react/prop-types
						href={`tel:${siteSettings?.phoneNumber}`}
						className="inline-flex items-center px-6 py-4 mb-2 mr-2 text-lg leading-6 text-white transition-colors bg-blue-400 border-2 border-blue-400 rounded-lg md:mb-0 hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 md:px-8 md:text-xl font-centra-medium focus:outline-0 focus:shadow-focus w-max"
					>
						{ctaLabel}
					</a>
				</div>
			</div>
		</section>
	);
};

export { AdviceBannerCA };

AdviceBannerCA.defaultProps = {
	variant: '',
	className: '',
};

AdviceBannerCA.propTypes = {
	adviceBannerContent: PropTypes.shape({
		title: titleProp.isRequired,
		content: PropTypes.string.isRequired,
		ctaLabel: PropTypes.string.isRequired,
		ctaLink: PropTypes.string,
		ctaOpenForm: PropTypes.bool,
	}).isRequired,
	variant: PropTypes.string,
	className: PropTypes.string,
};
