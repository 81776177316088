/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { PeninsulaNowAdvisorBanner } from '../../organisms/modal-peninsula-now/PeninsulaNowAdvisorBanner';
import SuccessTick from './success_tick.svg';

const SidebarHeader = ({
	heading,
	text,
	className,
	ariaLive,
	isOnline,
	openModal,
	pageName,
}) => (
	<header
		className={clsx(
			`px-6 pt-20 pb-10 bg-brand-pale-300`,
			className,
			pageName !== '' && isOnline && 'card flip z-10'
		)}
		aria-live={ariaLive || 'off'}
	>
		{pageName && (
			<PeninsulaNowAdvisorBanner
				isOnline={isOnline}
				setModal={openModal}
			/>
		)}
		<img src={SuccessTick} alt="" className="pb-5 " />
		<h2 className="text-2xl md:text-3xl font-centra-book text-[#1D2951]">
			<div dangerouslySetInnerHTML={{ __html: heading }} />
		</h2>
		{text && text !== '' ? (
			<p className="mt-4 text-lg leading-[1.4] font-centra-light text-[17px] text-[#1D2951]">
				{text}
			</p>
		) : null}
	</header>
);

SidebarHeader.defaultProps = {
	text: '',
	className: '',
	ariaLive: '',
	isOnline: false,
	openModal: () => {},
	pageName: '',
};

SidebarHeader.propTypes = {
	heading: PropTypes.string.isRequired,
	text: PropTypes.string,
	className: PropTypes.string,
	ariaLive: PropTypes.string,
	isOnline: PropTypes.bool,
	openModal: PropTypes.func,
	pageName: PropTypes.string,
};

export { SidebarHeader };
