/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useLocation } from '@reach/router';
import nextArrow from '../next_arrow.svg';

import { Button } from '../../../../../../atoms/button-ca';
import { CallUs } from '../../../../../../atoms/call-us';
import { SidebarHeader } from '../../../../../../atoms/sidebar-header';
import { TextInput } from '../../../../../../atoms/inline-text-input-ca';

import { useFormValidation } from '../../../../../../../hooks/form-validation-ca';
import { useSalesforceApiCA } from '../../../../../../../hooks/salesforce-ca';
import {
	sleep,
	sendTrackingData,
	getCookie,
	pageUrlFomater,
} from '../../../../../../../utils';

import { useSalesforceAzureApi } from '../../../../../../../hooks/salesforce-azure-ca';
import { splitFullName } from '../../../../../../../utils/split-full-name';

// Helper function to format phone number to E.164
const formatPhoneToE164 = (phoneNumber, countryCode = '1') => {
	const digits = phoneNumber.replace(/\D/g, '');
	if (phoneNumber.startsWith('+')) {
		return phoneNumber;
	}
	if (digits.startsWith(countryCode)) {
		return `+${digits}`;
	}
	return `+${countryCode}${digits}`;
};

const initialValidations = {
	fullName: { isValid: true },
	email: { isValid: true },
	phoneNumber: { isValid: true },
};

const AdviceFormCA = ({
	formTitle,
	setReference,
	setResponse,
	customFormFieldContent = {},
	className,
}) => {
	const fullNameRef = useRef(null);
	const emailRef = useRef(null);
	const phoneNumberRef = useRef(null);
	const [isError, setIsError] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const { validations, validateForm } = useFormValidation(initialValidations);
	const { fetchSalesforceCA } = useSalesforceApiCA();
	const { executeRecaptcha } = useGoogleReCaptcha();
	const { customFormTitle, customFormButton } = customFormFieldContent;
	const sideBarHeaderText = customFormTitle || 'Next';
	const submitButtonText = customFormButton || sideBarHeaderText;

	const { fetchSalesforceAzure } = useSalesforceAzureApi();
	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'advice-form',
			click_type: 'primary',
			click_title: sideBarHeaderText,
			click_text: submitButtonText,
		});
	};

	const handleCallbackTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('arrange_a_callback', {
			form_name: sideBarHeaderText,
		});
	};
	const locationUrl = useLocation();
	const pageUrl = pageUrlFomater(locationUrl);
	// Create an event handler so you can call the verification on button click event or form submit
	const submitHandler = async (event) => {
		if (!executeRecaptcha) {
			return;
		}
		event.preventDefault();

		const { formEntries, isValid } = validateForm(event.currentTarget);

		if (!isValid) return;

		setSubmitting(true);

		// Format phone number to E.164
		const formattedPhone = formatPhoneToE164(formEntries.phoneNumber);

		// Single dataLayer push with all necessary data
		if (window.dataLayer) {
			window.dataLayer.push({
				event: 'ec_form_submit',
				user_data: {
					email: formEntries.email,
					phone: formattedPhone,
				},
			});
		}

		const { firstName, lastName } = splitFullName(formEntries?.fullName);

		const payloadData = {
			firstName,
			lastName,
			phoneNumber: formEntries.phoneNumber,
			email: formEntries.email,
			leadSource: 'Website',
			leadSourceDetails: 'Contact Us',
			MSCLKID: getCookie('msclkid'),
			MSCLKID_last: getCookie('msclkid_last'),
			GCLID: getCookie('gclid'),
			GCLID_last: getCookie('gclid_last'),
			pageUrl,
			utmCampaign: getCookie('utm_campaign'),
			utmMedium: getCookie('utm_medium'),
			utmSource: getCookie('utm_source'),
			utmTerm: getCookie('utm_term'),
		};

		const response = await fetchSalesforceCA(
			executeRecaptcha,
			payloadData,
			'POST'
		);

		if (response.status !== 200) {
			localStorage.removeItem('formEmail');
			localStorage.setItem('formEmail', formEntries.email);
			await fetchSalesforceAzure(payloadData);
		}

		await sleep(500);
		setSubmitting(false);
		handleClickTracking();

		switch (response.status) {
			case 200:
				setResponse(
					`Brilliant, one of our advisors will call you as soon as possible.'
					}`
				);
				setReference(response.body.identifier);
				handleCallbackTracking();
				break;
			case 403:
				setIsError(true);
				break;
			default:
				setIsError(true);
				break;
		}
	};

	useEffect(() => {
		if (!validations.fullName.isValid && fullNameRef.current) {
			return fullNameRef.current.focus();
		}

		if (!validations.email.isValid && emailRef.current) {
			return emailRef.current.focus();
		}

		if (!validations.phoneNumber.isValid && phoneNumberRef.current) {
			return phoneNumberRef.current.focus();
		}
	}, [validations]);

	return isError ? (
		<>
			<SidebarHeader
				heading="We've run into a technical error with your submission"
				text="Don't worry though, it just means you need to give us a call instead and we'll be happy to help."
				ariaLive="assertive"
			/>
			<CallUs className="p-4" />
		</>
	) : (
		<>
			<div className="flex flex-col shadow-lg bg-white rounded-[0.75rem] border-1 border-blue-300">
				{/* title + status */}
				<div>
					{/* title */}
					<div className="bg-blue-500 rounded-t-[0.75rem] text-white p-5 sm:px-8 sm:py-5 font-centra-regular text-[20px] sm:text-[26px]">
						{formTitle}
					</div>
					{/* status bars */}

					<div className="flex flex-row h-2 w-full">
						<div className="bg-blue-300 w-1/2">&nbsp;</div>
						<div className="bg-blue-200 w-1/2">&nbsp;</div>
					</div>
				</div>
				<div className="px-8 py-5 leading-4 sm:leading-5">
					<span className="text-brand-blue-400 font-centra-regular text-[16px]">
						Fill in your details to get a{' '}
						<span className="text-blue-300">custom price</span> for
						your business
					</span>
				</div>
				<form
					onSubmit={(e) => submitHandler(e)}
					className={`flex flex-col justify-between w-full mx-auto grow ${className}`}
				>
					<div className="flex flex-col grow">
						<fieldset
							className="flex flex-col px-8 grow"
							disabled={submitting}
						>
							<TextInput
								labelText="Full name"
								id="callbackFirstName"
								name="fullName"
								aria-required="true"
								className="w-full wrapper-small pb-1 text-sm"
								validationMessage="Please enter your full name"
								showValidation={!validations.fullName.isValid}
								ref={fullNameRef}
								placeHolder="Quinn Piper"
								isRequired
							/>

							<TextInput
								labelText="Phone number"
								type="tel"
								id="callbackPhoneNumber"
								name="phoneNumber"
								aria-required="true"
								className="w-full wrapper-small pb-1 text-sm"
								validationMessage="Please enter a phone number"
								showValidation={
									!validations.phoneNumber.isValid
								}
								ref={phoneNumberRef}
								placeHolder="### ### ####"
								isRequired
							/>

							<TextInput
								labelText="Email address"
								id="callbackEmail"
								name="email"
								aria-required="true"
								className="w-full wrapper-small pb-1 text-sm"
								validationMessage="Please enter your email address"
								showValidation={!validations.email.isValid}
								ref={emailRef}
								placeHolder="email@gmail.com"
								isRequired
							/>
						</fieldset>
					</div>
					<div className="flex flex-row justify-between items-center px-8 pb-8 pt-5">
						<div className="wrapper-small">
							<Button
								type="submit"
								className={clsx(
									submitting && 'justify-center loading'
								)}
								variant="Red"
							>
								<div
									className={`flex flex-row items-center justify-center visible ${
										submitting && 'invisible'
									}`}
								>
									<div>{submitButtonText} &nbsp;</div>
									<div>
										<img src={nextArrow} alt="" />
									</div>
								</div>
								{submitting ? (
									<span
										className="sr-only"
										aria-live="assertive"
									>
										Submitting your details, please wait...
									</span>
								) : null}
							</Button>
						</div>
						<div className="text-[#A5A5A5] text-lg sm:text-xl">
							1 of 2
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

AdviceFormCA.propTypes = {
	setReference: PropTypes.func.isRequired,
	setResponse: PropTypes.func.isRequired,
};

export { AdviceFormCA };
