import { clsx } from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useLocation } from '@reach/router';
import { Button } from '../../atoms/button';
// eslint-disable-next-line import/no-cycle
import { CallUs } from '../../atoms/call-us';
import { SidebarHeader } from '../../atoms/sidebar-header';
import { TextInput } from '../../atoms/text-input';

import { useFormValidation } from '../../../hooks/form-validation';
import { useSalesforceApi } from '../../../hooks/salesforce';
import usePrivacyLink from '../../../hooks/usePrivacyLink';
import {
	generateUtmData,
	getCookie,
	pageUrlFomater,
	sendTrackingData,
	sleep,
} from '../../../utils';
import { getLocale } from '../../../utils/locale';
import { PBSLink } from '../../atoms/link';

import { useSalesforceAzureApi } from '../../../hooks/salesforce-azure-ca';

const initialValidations = {
	firstName: { isValid: true },
	lastName: { isValid: true },
	email: { isValid: true },
	company: { isValid: true },
	phoneNumber: { isValid: true },
	callFrom: { isValid: true },
	callTo: { isValid: true },
};

const AdviceForm = ({
	setReference,
	leadSource,
	setResponse,
	openModal,
	pageName,
	isOnline,
}) => {
	const firstNameRef = useRef(null);
	const lastNameRef = useRef(null);
	const companyRef = useRef(null);
	const emailRef = useRef(null);
	const phoneNumberRef = useRef(null);
	// const [chooseTime, setChooseTime] = useState(false);
	const [isError, setIsError] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const location = useLocation();
	const locale = getLocale(location.pathname);
	const { validations, validateForm } = useFormValidation(initialValidations);
	const { fetchSalesforce } = useSalesforceApi();
	const { executeRecaptcha } = useGoogleReCaptcha();
	const { privacyLink } = usePrivacyLink(locale);
	let isSelectedTimes = false;
	const sideBarHeaderText = 'Book my free advice call';
	const submitButtonText = sideBarHeaderText;
	const { fetchSalesforceAzure } = useSalesforceAzureApi();
	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'advice-form',
			click_type: 'primary',
			click_title: sideBarHeaderText,
			click_text: submitButtonText,
		});
	};

	const handleCallbackTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('arrange_a_callback', {
			form_name: sideBarHeaderText,
		});
	};
	const locationUrl = useLocation();
	const pageUrl = pageUrlFomater(locationUrl);
	// Create an event handler so you can call the verification on button click event or form submit
	const submitHandler = async (event) => {
		if (!executeRecaptcha) {
			return;
		}
		event.preventDefault();

		const { formEntries, isValid } = validateForm(event.currentTarget);

		const todaysDate = new Date().toISOString().split('T')[0];

		if (!isValid) return;

		setSubmitting(true);

		isSelectedTimes = formEntries.callFrom && formEntries.callTo;
		const selectedDate = isSelectedTimes ? formEntries.callDay : todaysDate;
		const selectedTime = isSelectedTimes
			? `${formEntries.callFrom}-${formEntries.callTo}`
			: 'asap';

		const payloadData = {
			firstName: formEntries.firstName,
			lastName: formEntries.lastName,
			company: formEntries.company,
			phoneNumber: formEntries.phoneNumber,
			email: formEntries.email,
			selectedDate,
			selectedTime,
			leadSource,
			locale,
			MSCLKID: getCookie('msclkid'),
			MSCLKID_last: getCookie('msclkid_last'),
			GCLID: getCookie('gclid'),
			GCLID_last: getCookie('gclid_last'),
			pageUrl,
		};

		if (locale === '' && (locale !== 'ca' || locale !== 'ie')) {
			payloadData.utmCampaign = getCookie('utm_campaign');
			payloadData.utmMedium = getCookie('utm_medium');
			payloadData.utmSource = getCookie('utm_source');
			payloadData.utmTerm = getCookie('utm_term');
		}

		if (locale === 'ca') {
			payloadData.leadSource = 'Website';
			payloadData.utmCampaign = getCookie('utm_campaign');
			payloadData.utmMedium = getCookie('utm_medium');
			payloadData.utmSource = getCookie('utm_source');
			payloadData.utmTerm = getCookie('utm_term');
		}

		if (locale === 'ie') {
			payloadData.leadSource = 'Website - ROI Contact us';
			payloadData.utmCampaign = generateUtmData(pageUrl, 'utm_campaign');
			payloadData.utmMedium = generateUtmData(pageUrl, 'utm_medium');
			payloadData.utmSource = generateUtmData(pageUrl, 'utm_source');
			payloadData.utmTerm = generateUtmData(pageUrl, 'utm_term');
		}

		const response = await fetchSalesforce(
			executeRecaptcha,
			payloadData,
			'POST'
		);

		if (locale === 'ca' && response.status !== 200) {
			localStorage.removeItem('formEmail');
			localStorage.setItem('formEmail', formEntries.email);
			await fetchSalesforceAzure(payloadData);
		}

		await sleep(500);
		setSubmitting(false);
		handleClickTracking();

		switch (response.status) {
			case 200:
				setResponse(
					`Brilliant, one of our advisors will call you ${
						isSelectedTimes
							? `between the hours of ${
									formEntries.callFrom
							  } and ${formEntries.callTo} ${
									todaysDate === formEntries.callDay
										? 'today'
										: 'tomorrow'
							  }.`
							: 'as soon as possible.'
					}`
				);
				setReference(response.body.identifier);
				handleCallbackTracking();
				break;
			case 403:
				setIsError(true);
				break;
			default:
				setIsError(true);
				break;
		}
	};

	useEffect(() => {
		if (!validations.firstName.isValid && firstNameRef.current) {
			return firstNameRef.current.focus();
		}

		if (!validations.lastName.isValid && lastNameRef.current) {
			return lastNameRef.current.focus();
		}
		if (!validations.company.isValid && companyRef.current) {
			return companyRef.current.focus();
		}

		if (!validations.email.isValid && emailRef.current) {
			return emailRef.current.focus();
		}

		if (!validations.phoneNumber.isValid && phoneNumberRef.current) {
			return phoneNumberRef.current.focus();
		}
	}, [validations]);

	return isError ? (
		<>
			<SidebarHeader
				heading="We've run into a technical error with your submission"
				text="Don't worry though, it just means you need to give us a call instead and we'll be happy to help."
				ariaLive="assertive"
			/>
			<CallUs className="p-4" />
		</>
	) : (
		<>
			<form
				onSubmit={submitHandler}
				className="flex flex-col justify-between w-full mx-auto grow"
			>
				<SidebarHeader
					pageName={pageName}
					isOnline={isOnline}
					heading={sideBarHeaderText}
					openModal={openModal}
					text="Tell us your details and availability - one of our professionals will get back to you."
				/>
				<div className="flex flex-col grow">
					<fieldset
						className="flex flex-col px-4 pt-10 bg-white grow"
						disabled={submitting}
					>
						<legend className="sr-only">
							Enter your personal details
						</legend>
						<TextInput
							labelText="First name"
							id="callbackFirstName"
							name="firstName"
							aria-required="true"
							className="w-full wrapper-small"
							validationMessage="Please enter your first name"
							showValidation={!validations.firstName.isValid}
							ref={firstNameRef}
						/>

						<TextInput
							labelText="Last name"
							id="callbackLastName"
							name="lastName"
							aria-required="true"
							className="w-full wrapper-small"
							validationMessage="Please enter your last name"
							showValidation={!validations.lastName.isValid}
							ref={lastNameRef}
						/>

						<TextInput
							labelText="Company"
							id="callbackCompany"
							name="company"
							aria-required="false"
							className="w-full wrapper-small"
							validationMessage="Please enter your company name"
							ref={companyRef}
						/>

						<TextInput
							labelText="Email address"
							id="callbackEmail"
							name="email"
							aria-required="true"
							className="w-full wrapper-small"
							validationMessage="Please enter your email address"
							showValidation={!validations.email.isValid}
							ref={emailRef}
						/>

						<TextInput
							labelText="Best number to call you on"
							type="tel"
							id="callbackPhoneNumber"
							name="phoneNumber"
							aria-required="true"
							className="w-full wrapper-small"
							validationMessage="Please enter a phone number"
							showValidation={!validations.phoneNumber.isValid}
							ref={phoneNumberRef}
						/>

						{/* <div className="w-full wrapper-small">
							<fieldset
								className={clsx(chooseTime ? 'mb-4' : null)}
								disabled={submitting}
							>
								<legend className="mb-1 text-brand-blue-400 text-base-f font-centra-medium">
									When shall we call you?
								</legend>

								<p className="mb-4 text-s-f">
									Callbacks can be as quick as within 8
									minutes
								</p>

								<div className="flex flex-wrap gap-2">
									<RadioButton
										defaultChecked
										labelText="As soon as possible"
										id="callAsap"
										value="callAsap"
										name="callWhen"
										onChange={() => setChooseTime(false)}
									/>
									<RadioButton
										labelText="Choose a time"
										id="chooseTime"
										value="chooseTime"
										name="callWhen"
										onChange={() => setChooseTime(true)}
									/>
								</div>
							</fieldset>
						</div> */}
					</fieldset>

					{/* <div
						className="px-4 py-10 bg-brand-pale-300"
						hidden={!chooseTime}
					>
						<TimesRange
							callFromIsValid={
								chooseTime &&
								validations.callFrom &&
								validations.callFrom.isValid
							}
							callToIsValid={
								chooseTime &&
								validations.callTo &&
								validations.callTo.isValid
							}
							isDisabled={!chooseTime}
						/>
					</div> */}
				</div>
				{locale === 'ie' || locale === 'ca' ? (
					<p className="mx-6 text-base-f">
						View our{' '}
						<PBSLink to={privacyLink} variant="Link">
							{' '}
							privacy notice{' '}
						</PBSLink>
					</p>
				) : null}
				<div className="px-4 py-7 bg-white">
					<div className="wrapper-small">
						<Button
							type="submit"
							className={clsx(
								submitting && 'justify-center loading'
							)}
						>
							<span
								className={clsx(
									submitting ? 'invisible' : null
								)}
							>
								{submitButtonText}
							</span>
							{submitting ? (
								<span className="sr-only" aria-live="assertive">
									Submitting your details, please wait...
								</span>
							) : null}
						</Button>
					</div>
				</div>
			</form>
		</>
	);
};

AdviceForm.defaultProps = {
	openModal: () => {},
	pageName: '',
	isOnline: false,
	leadSource: 'Website - Become a Client',
};
AdviceForm.propTypes = {
	setReference: PropTypes.func.isRequired,
	setResponse: PropTypes.func.isRequired,
	openModal: PropTypes.func,
	pageName: PropTypes.string,
	isOnline: PropTypes.bool,
	leadSource: PropTypes.string,
};

export { AdviceForm };
