/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { parseMarkdown } from '../../../utils/parse-md';
import { createHeadingHighlightCA } from '../../../utils/heading-highlight-ca';
import * as headingStyles from './index.module.css';
import { bannerDefaultContent } from './data';
import { Button } from '../button-ca';
import { useModal } from '../../../contexts/modal-context';

const CtaBannerPPCV2 = ({ ctaBannerContent, modalRef }) => {
	const { setModalRef } = useModal();
	const { bannerImage, bannerHeading, bannerContent, ctaLabel } =
		ctaBannerContent;

	const titleText = createHeadingHighlightCA({
		variant: 'brand-blue-underline',
		headings:
			bannerHeading?.value?.document?.children[0]?.children ||
			bannerDefaultContent.bannerHeading?.value?.document?.children[0]
				?.children,
	});

	const bannerContentParsed = parseMarkdown(
		bannerContent || bannerDefaultContent?.bannerContent
	);

	return (
		<section
			id="cta-banner-ppc-v2"
			className={`py-5 md:pt-12 md:py-16 lg:pt-12 lg:pb-10 xl:py-5 ${headingStyles?.bgGradientPpc}`}
		>
			<div className="flex flex-col gap-5 lg:flex-row items-center max-w-m-screen mx-auto px-5 md:px-8 lg:px-10">
				{/* left panel - img  */}
				<div className="mx-auto lg:w-full">
					<GatsbyImage
						image={getImage(
							bannerImage || bannerDefaultContent?.bannerImage
						)}
						loading="lazy"
						className="object-contain"
					/>
				</div>
				{/* right panel - content */}
				<div className="flex flex-col gap-5 justify-center items-center max-w-sm sm:max-w-xl md:max-w-3xl lg:max-w-6xl xl:max-w-7xl mx-auto text-center">
					{/* title */}
					<div className="-mb-1 text-3xl md:text-5xl font-castledown-regular leading-[1.2] md:leading-[1.3] text-center lg:text-left">
						{titleText}
					</div>
					{/* content */}
					{bannerContent !== null ? (
						<div
							className="text-[17px] md:text-[20px] lg:text-xl font-centra-light text-center lg:text-justify"
							dangerouslySetInnerHTML={{
								__html: bannerContentParsed,
							}}
						/>
					) : (
						<div className="text-[17px] md:text-[20px] lg:text-xl font-centra-light text-center lg:text-justify">
							<p>
								We’ve helped over{' '}
								<strong>6,500 Canadian businesses grow</strong>{' '}
								by taking the load of HR and health &amp; safety
								compliance off their backs. Call our experienced
								advisors today and get answers to all your HR
								and health &amp; safety queries.
							</p>
						</div>
					)}
					<div className="hidden md:block my-5 mx-auto lg:mx-0 lg:mr-auto">
						<Button
							onClick={() => setModalRef(modalRef)}
							variant="Red"
						>
							{ctaLabel || bannerDefaultContent?.ctaLabel}
						</Button>
					</div>
					{/* for mobile */}
					<div className="block md:hidden mx-auto pb-5">
						<Button
							onClick={() => {
								window.location.href = '#adviceform';
							}}
							variant="Red"
						>
							{ctaLabel || bannerDefaultContent?.ctaLabel}
						</Button>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CtaBannerPPCV2;
