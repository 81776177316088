/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { TextWithImage } from '../../atoms/text-with-image-ppc-v2';
import { HeadingTagLine } from '../../atoms/heading-tag-line-ppc';
import { HeadingTagLine2 } from '../../atoms/heading-tag-line-ppc2';
import { HeadingTagLine3 } from '../../atoms/heading-tag-line-ppc3';

import {
	datoGatsbyImageProp,
	titleProp,
	structuredTextContent,
	refProp,
} from '../../../types';
import { createHeadingHighlightCA } from '../../../utils/heading-highlight-ca';

const TextWithImageContainer = ({
	textWithImageContainerContent,
	modalRef,
}) => {
	const { id } = textWithImageContainerContent;
	let textWithImages = [textWithImageContainerContent];
	let titleText = '';

	if (
		!Object.prototype.hasOwnProperty.call(
			textWithImageContainerContent,
			'content'
		)
	) {
		const {
			title,
			firsttitle,
			firstContent,
			firstImage,
			firstButtonCopy,
			firstDisplayCtaOnPage,
			secondtitle,
			secondContent,
			secondImage,
			secondButtonCopy,
			secondDisplayCtaOnPage,
			thirdtitle,
			thirdContent,
			thirdImage,
			thirdButtonCopy,
			thirdDisplayCtaOnPage,
		} = textWithImageContainerContent;

		const elementOne = {
			titleProps: {
				id,
				firsttitle,
				headingClassNames:
					'text-left text-3xl md:!text-4xl md:!leading-normal lg:max-w-[470px]',
			},
			as: HeadingTagLine,
			content: firstContent,
			image: firstImage,
			firstButtonCopy,
			firstDisplayCtaOnPage,
			className: 'items-center mb-8 lg:mb-2xl-f bg-red-200',
		};

		const elementTwo = {
			titleProps2: {
				id,
				secondtitle,
				headingClassNames:
					'text-left text-3xl md:!text-4xl md:!leading-normal lg:max-w-[470px]',
			},
			as: HeadingTagLine2,
			content: secondContent,
			image: secondImage,
			secondButtonCopy,
			secondDisplayCtaOnPage,
			className: 'items-center mb-8 lg:mb-2xl-f bg-red-200',
		};

		const elementThree = {
			titleProps3: {
				id,
				thirdtitle,
				headingClassNames:
					'text-left text-3xl md:!text-4xl md:!leading-normal lg:max-w-[470px]',
			},
			as: HeadingTagLine3,
			content: thirdContent,
			image: thirdImage,
			thirdButtonCopy,
			thirdDisplayCtaOnPage,
			className: 'items-center',
		};

		textWithImages = [elementOne, elementTwo, elementThree];

		if (title.value !== null) {
			titleText = createHeadingHighlightCA({
				variant: 'brand-blue-underline',
				headings: title?.value.document.children[0].children || [],
			});
		}
	}

	const defaultTitleText = (
		<div
			className="flex justify-center px-4 text-center mx-auto max-w-m-screen pb-5 lg:pb-10 text-3xl md:text-5xl font-castledown-regular leading-[1.2] md:leading-[1.3] lg:mb-5
		"
		>
			<div>
				<span className="text-blue-600">The&nbsp;</span>
				<span className="text-blue-400 pb-1 bg-no-repeat bg-underlineblue bg-bottom bg-[length:100%_6px] md:bg-[length:100%_8px]">
					HR partner
				</span>
				<span className="text-blue-600">&nbsp;you can count on</span>
			</div>
		</div>
	);

	return (
		<section className="py-10 md:pt-16 lg:pt-[4.5rem] md:pb-24 max-w-m-screen mx-auto px-2">
			{titleText ? (
				<div className="flex justify-center px-4 text-center mx-auto max-w-m-screen pb-5 lg:pb-10 text-3xl md:text-5xl font-castledown-regular leading-[1.2] md:leading-[1.3] lg:mb-5">
					{titleText}
				</div>
			) : (
				defaultTitleText
			)}
			<div
				className="px-4 mx-auto md:px-8 max-w-m-screen "
				aria-labelledby={id}
			>
				{textWithImages.map((elContent, index) => {
					let flipSecond = false;
					if (textWithImages.length > 1) {
						flipSecond = true;
					}

					return (
						<TextWithImage
							// eslint-disable-next-line react/no-array-index-key
							key={`${id}${index}`}
							textWithImageContent={elContent}
							modalRef={modalRef}
							{...((flipSecond && index === 0) ||
							(flipSecond && index === 2)
								? { flip: true }
								: null)}
						/>
					);
				})}
			</div>
		</section>
	);
};

TextWithImageContainer.propTypes = {
	textWithImageContainerContent: PropTypes.shape({
		id: PropTypes.string.isRequired,
		firsttitle: PropTypes.oneOfType([titleProp, PropTypes.string])
			.isRequired,
		secondtitle: PropTypes.oneOfType([titleProp, PropTypes.string])
			.isRequired,
		thirdtitle: PropTypes.oneOfType([titleProp, PropTypes.string])
			.isRequired,
		firstButtonCopy: PropTypes.string,
		secondButtonCopy: PropTypes.string,
		thirdButtonCopy: PropTypes.string,
		buttonLink: PropTypes.string,
		content: structuredTextContent,
		firstContent: structuredTextContent,
		secondContent: structuredTextContent,
		thirdContent: structuredTextContent,
		image: datoGatsbyImageProp,
		firstImage: datoGatsbyImageProp,
		secondImage: datoGatsbyImageProp,
		thirdImage: datoGatsbyImageProp,
		firstDisplayCtaOnPage: PropTypes.bool,
		secondDisplayCtaOnPage: PropTypes.bool,
		thirdDisplayCtaOnPage: PropTypes.bool,
	}).isRequired,
	modalRef: refProp.isRequired,
};

export { TextWithImageContainer };
