/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useLocation } from '@reach/router';
import { BusinessReviewHorizontal } from '../../atoms/business-reviews';

import { createHeadingHighlightCA } from '../../../utils/heading-highlight-ca';

import * as headingStyles from './index.module.css';

import tick from './tick.svg';
import { AdviceFormCA } from '../ca/forms/salesforce/inline-two-steps/advice-step-one';
import { SuccessFormCA } from '../ca/forms/salesforce/inline-two-steps/success-step-two';

const PricingHeroCA = ({ heroContentCA, customFormField, className }) => {
	const { title, subtitles, googleReview } = heroContentCA;
	const { customFormTitle } = customFormField;
	const [response, setResponse] = useState('');
	const [reference, setReference] = useState('');

	const id = 'main-site-header-v2-ca';
	const as = 'h1';
	const Component = as;
	const titleText = createHeadingHighlightCA({
		variant: 'brand-red-underline',
		headings: title.value?.document?.children[0]?.children,
		headingStyles,
	});

	const getPrimaryRows = (row) =>
		createHeadingHighlightCA({
			variant: 'black',
			headings: row?.children,
			headingStyles,
			className:
				'font-castledown-regular text-[21px] sm:text-[24px] lg:text-[20px] xl:text-[20px] ',
			highlightClassName:
				'font-castledown-regular text-[21px] sm:text-[24px] lg:text-[20px] xl:text-[20px] font-bold',
		});

	const [locationParam, setLocationParam] = useState(null);
	const url = useLocation();

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const urlParams = new URLSearchParams(url.search);
			const param = urlParams.get('h1');

			setLocationParam(param);
		}
	}, [url.search]);

	const formTitle = 'Get our pricing plans today!';

	return (
		<section
			role="banner"
			className={`${headingStyles?.bgGradientPpc} px-4 xl:px-8 mx-auto lg:min-w-m-screen sm:mt-0 md:mb-0 lg:mb-0 xl:mb-0 ${className}`}
			aria-labelledby={id}
		>
			<div className="flex flex-row items-center justify-center max-w-m-screen mx-auto py-10 lg:p-6 xl:px-2 2xl:px-10">
				{/* left panel */}
				<div className="w-full lg:mb-2 xl:m-0">
					<div className="flex mx-auto">
						<div className="flex flex-col">
							<div className="flex flex-col gap-5 inline !leading-[1.1] font-castledown-regular lg:mx-0 inline text-4xl md:text-[56px]">
								{/* title div */}
								<div>
									<Component id={id} className="inline">
										{locationParam || titleText}
									</Component>
									<span
										id="dynamic-loc"
										className="inline dynamic-loc"
									/>
								</div>
								{/* sub-title div */}
								<div className="hidden md:block">
									<div className="m-2 list-none leading-[1.2] block xl:leading-[1.5] text-2xl md:text-3xl">
										{subtitles.length > 0 &&
											subtitles.map((elm) => (
												<div className="flex flex-row gap-4 my-3 xl:my-1">
													<div className="mt-2 min-w-[20px] max-w-[20px]">
														<img
															src={tick}
															alt="Tick"
														/>
													</div>
													<div>
														<div
															className={`max-w-full text-[21px] sm:text-[24px] lg:text-[20px] xl:text-[22px] ${headingStyles.bulletsHeroPpc}`}
														>
															{getPrimaryRows(
																elm
																	.structuredTextBlock
																	?.value
																	?.document
																	?.children[0]
															)}
														</div>
													</div>
												</div>
											))}
									</div>
								</div>
								<div
									className="block md:hidden py-5 scroll-mt-[425px]"
									id="adviceform"
								>
									{reference === '' ? (
										<AdviceFormCA
											pageName="PrioingPg"
											formTitle={
												customFormTitle || formTitle
											}
											setReference={setReference}
											setResponse={setResponse}
										/>
									) : (
										<SuccessFormCA
											formTitle={
												customFormTitle || formTitle
											}
											reference={reference}
											response={response}
										/>
									)}
								</div>
								{/* GR logo div */}
								<div className="-mt-8 mr-auto">
									<BusinessReviewHorizontal
										googleReview={googleReview}
										onlyGR
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* right panel */}
				<div
					className="hidden w-2/3 text-left md:block scroll-mt-[136px]"
					id="advice-form"
				>
					{reference === '' ? (
						<AdviceFormCA
							pageName="PrioingPg"
							formTitle={customFormTitle || formTitle}
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessFormCA
							formTitle={customFormTitle || formTitle}
							reference={reference}
							response={response}
						/>
					)}
				</div>
			</div>
		</section>
	);
};

PricingHeroCA.propTypes = {
	googleReview: PropTypes.shape({
		url: PropTypes.string.isRequired,
		alt: PropTypes.string.isRequired,
	}).isRequired,
};

export { PricingHeroCA };
