/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import { useLocation } from '@reach/router';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { BusinessReviewHorizontal } from '../../atoms/business-reviews';

import { titleProp, datoGatsbyImageProp, refProp } from '../../../types';
import { createHeadingHighlightCA } from '../../../utils/heading-highlight-ca';

import * as headingStyles from './index.module.css';

import tick from './tick.svg';
import CTAbuttonsCA from '../../atoms/cta-buttons-ca';

const HeroPpcV2CA = ({
	id,
	header,
	secondaryHeaderRows,
	secondaryHeader,
	as,
	googleReview,
	primaryLink,
	primaryLinkCopy,
	primaryLinkOpenForm,
	primaryImage,
	showTrustPilot: showBusinessReview,
	modalRef,
	logo,
}) => {
	const Component = as;
	const titleText = createHeadingHighlightCA({
		variant: 'brand-blue-underline',
		headings: header[0].children,
		headingStyles,
	});

	const [locationParam, setLocationParam] = useState(null);
	const url = useLocation();

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const urlParams = new URLSearchParams(url.search);
			const param = urlParams.get('h1');
			setLocationParam(param);
		}
	}, [url.search]);

	const primaryImageData = getImage(primaryImage?.gatsbyImageData);

	return (
		<section
			role="banner"
			className={`${headingStyles?.bgGradientPpc} px-4 xl:px-8 mx-auto lg:min-w-m-screen sm:mt-0 md:mb-0 lg:mb-0 xl:mb-0`}
			aria-labelledby={id}
		>
			<div className="flex flex-row items-center justify-center max-w-m-screen mx-auto py-10 md:py-4 lg:px-6 xl:px-2 2xl:px-10">
				{/* left panel */}
				<div className="w-full lg:mb-2 xl:m-0">
					<div className={clsx('flex mx-auto')}>
						<div className={clsx('flex flex-col')}>
							{logo && (
								<img
									src={logo.url}
									alt={logo.alt || ''}
									className="max-w-[275px]"
								/>
							)}
							<div className="flex flex-col gap-5 inline font-castledown-regular mx-5 lg:mx-0 inline text-3xl md:text-5xl leading-[1.2] md:leading-[1.3]">
								{/* title div */}
								<div>
									<Component id={id} className="inline">
										{locationParam || titleText}
									</Component>
									<span
										id="dynamic-loc"
										className="inline dynamic-loc"
									/>
								</div>
								{/* sub-title div */}
								<div className="-mt-2">
									{secondaryHeaderRows.length > 0 ? (
										<div
											className={`
											mx-2
											list-none
											leading-[1.2] block
											xl:leading-[1.5]
											text-2xl md:text-3xl
										`}
										>
											{secondaryHeaderRows.map(
												({ secondaryHeaderRow }) => (
													<div className="flex flex-row gap-4 my-3 xl:my-1">
														<div className="mt-2 min-w-[20px] max-w-[20px]">
															<img
																src={tick}
																alt="Tick"
															/>
														</div>
														<div>
															<div
																className={`max-w-full text-[21px] sm:text-[24px] lg:text-[20px] xl:text-[22px] ${headingStyles.bulletsHeroPpc}`}
															>
																{
																	secondaryHeaderRow
																}
															</div>
														</div>
													</div>
												)
											)}
										</div>
									) : (
										<span
											className={`
												mt-2 leading-[1.2] block md:mt-2 xl:leading-[1.3] text-2xl md:text-3xl ${headingStyles.heading}`}
										>
											{secondaryHeader}
										</span>
									)}
								</div>
								{/* CTA div */}
								<div className="-my-5">
									<CTAbuttonsCA
										primaryLinkCopy={primaryLinkCopy}
										primaryLink={primaryLink}
										primaryLinkOpenForm={
											primaryLinkOpenForm
										}
										secondaryLinkCopy={null}
										secondaryLink={null}
										secondaryLinkOpenForm={null}
										modalRef={modalRef}
										useModalForm
										drawerRef={null}
										variant="col"
										buttonVariant="Red"
										className="md:justify-self-start"
									/>
								</div>
								{/* GR logo div */}
								<div className="-mt-8">
									{showBusinessReview ? (
										<BusinessReviewHorizontal
											googleReview={googleReview}
											onlyGR
										/>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* right panel */}
				<div className="hidden w-full text-right xl:block">
					<GatsbyImage
						image={primaryImageData}
						alt={primaryImage.alt}
						loading="lazy"
						className=""
					/>
				</div>
			</div>
		</section>
	);
};

HeroPpcV2CA.defaultProps = {
	id: 'main-site-header-v2-ca',
	as: 'h1',
	secondaryHeader: '',
	showTrustPilot: true,
	logo: {},
	secondaryHeaderRows: [],
};

HeroPpcV2CA.propTypes = {
	id: PropTypes.string,
	header: titleProp.isRequired,
	secondaryHeader: PropTypes.string,
	as: PropTypes.string,
	primaryImage: datoGatsbyImageProp.isRequired,
	primaryLink: PropTypes.string.isRequired,
	primaryLinkCopy: PropTypes.string.isRequired,
	primaryLinkOpenForm: PropTypes.bool.isRequired,
	showTrustPilot: PropTypes.bool,
	secondaryHeaderRows: PropTypes.arrayOf(PropTypes.string),
	modalRef: refProp,
	logo: datoGatsbyImageProp,
	googleReview: PropTypes.shape({
		url: PropTypes.string.isRequired,
		alt: PropTypes.string.isRequired,
	}).isRequired,
};

export { HeroPpcV2CA };
