/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import SVG from 'react-inlinesvg';

import * as logoClasses from './index.module.css';

const clientLogosMobile = [
	{
		src: 'clients/mobile/logo-mobile-timhortons',
		alt: 'Tim Hortons',
		width: 100,
		height: 61,
	},
	{
		src: 'clients/mobile/logo-mobile-peterpauls',
		alt: 'Peter and Pauls Hospitality Group',
		width: 120,
		height: 46,
	},
	{
		src: 'clients/mobile/logo-mobile-dq',
		alt: 'DQ',
		width: 50,
		height: 65,
	},
	{
		src: 'clients/mobile/logo-mobile-niagara',
		alt: 'Niagara Falls ',
		width: 180,
		height: 100,
	},
	{
		src: 'clients/mobile/logo-mobile-dominos',
		alt: 'Dominos',
		width: 40,
		height: 50,
	},
];

const clientLogos = [
	{
		src: 'clients/desktop/logo-desktop-timhortons',
		alt: 'Tim Hortons',
		width: 194,
		height: 61,
	},
	{
		src: 'clients/desktop/logo-desktop-peterpauls',
		alt: 'Peter and Pauls Hospitality Group',
		width: 170,
		height: 46,
	},
	{
		src: 'clients/desktop/logo-desktop-dq',
		alt: 'DQ',
		width: 60,
		height: 65,
	},
	{
		src: 'clients/desktop/logo-desktop-niagara',
		alt: 'Niagara Falls ',
		width: 251,
		height: 100,
	},
	{
		src: 'clients/desktop/logo-desktop-dominos',
		alt: 'Dominos',
		width: 50,
		height: 50,
	},
];

const ClientLogosCA = ({
	pathname,
	isMobile = false,
	className,
	logoClassName,
	logoCopyClassName,
}) => {
	const logoCopy = 'Trusted by over 6,500 businesses across Canada';
	const getLogos = (logos, filetype) =>
		logos.map(({ src, alt, width, height }) => {
			const iconSrc =
				filetype === 'svg'
					? // eslint-disable-next-line import/no-dynamic-require, global-require
					  require(`!!file-loader!../../../assets/${src}.svg`)
							.default
					: `/images/ca/logos/${src}.${filetype}`;

			return (
				<li
					className={clsx(
						'flex',
						'mt-4',
						'max-w-[26%] ml-6',
						'md:ml-8 md:mt-8',
						'lg:ml-0 lg:mt-0',
						logoClasses.clientLogo
					)}
					key={alt}
				>
					{filetype === 'svg' ? (
						<SVG
							src={iconSrc}
							role="img"
							aria-label={alt}
							width={width}
							height={height}
						/>
					) : (
						<img src={iconSrc} alt={alt} />
					)}
				</li>
			);
		});

	return isMobile ? (
		// For mobile
		<section
			className={clsx('block sm:hidden px-4 pb-8 md:py-20', className)}
			aria-label="Logos of clients that we have worked with"
		>
			<div
				className={`flex flex-row text-sm pb-5 mt-7 justify-center items-center font-centra-light font-black text-center ${logoClassName}`}
			>
				{logoCopy}
			</div>
			<div className="ml-6 md:w-[768px] lg:w-auto md:mx-auto max-w-m-screen lg:px-8">
				<ul
					className={clsx(
						'flex',
						'flex-wrap',
						'items-center',
						'justify-center',
						'lg:justify-between',
						'justify-center',
						'-ml-4 -mt-4 mx-auto',
						'md:-ml-8 md:-mt-8',
						'lg:ml-0 lg:mt-0 lg:justify-between'
					)}
				>
					{getLogos(clientLogosMobile, 'png')}
				</ul>
			</div>
		</section>
	) : (
		// For Desktop or larger screen
		<section
			className={clsx(
				'hidden md:block px-4 py-10 md:py-20',
				pathname !== '/' ? 'hidden md:block' : null,
				className
			)}
			aria-label="Logos of clients that we have worked with"
		>
			<div
				className={`flex flex-row text-2xl pb-5 mt-7 justify-center items-center font-centra-light font-black ${logoCopyClassName}`}
			>
				{logoCopy}
			</div>
			<div
				className={`md:max-w-2xl lg:max-w-7xl lg:w-auto md:mx-auto max-w-m-screen lg:px-8 ${logoClassName}`}
			>
				<ul
					className={clsx(
						'flex',
						'flex-wrap',
						'md:justify-center',
						'items-center',
						'justify-between',
						'-ml-4 -mt-4 mx-auto',
						'md:-ml-8 md:-mt-8',
						'lg:ml-0 lg:mt-0 lg:justify-between'
					)}
				>
					{getLogos(clientLogos, 'png')}
				</ul>
			</div>
		</section>
	);
};

ClientLogosCA.propTypes = {
	pathname: PropTypes.string.isRequired,
	isMobile: PropTypes.bool.isRequired,
	// eslint-disable-next-line react/require-default-props
	className: PropTypes.string,
};

export { ClientLogosCA };
