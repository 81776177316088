import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { HeadingTagLine } from '../../atoms/heading-tag-line';
import tickIcon from './tick-icon.svg';

import { titleProp } from '../../../types';

// eslint-disable-next-line react/prop-types
const ServicesBannerCA = ({ id, servicesContent, siteSettings }) => {
	// eslint-disable-next-line react/prop-types
	const { title, subText, ctaLabel } = servicesContent;

	const getTitle = () => (
		<HeadingTagLine
			id={id}
			// eslint-disable-next-line react/prop-types
			title={title.value.document.children}
			headingClassNames={clsx(
				'font-centra-no1 font-light text-center text-white -mt-2 xl:text-4xl 2xl:text-5xl'
			)}
		/>
	);

	return (
		<div
			className="flex flex-col text-white items-center justify-center w-full sm:gap-0 font-castledown-regular bg-blue-500 px-7 py-16 lg:pt-16 xl:pb-16 xl:pt-16 xl:mb-14 gap-10 2xl:py-20 2xl:mt-24 font-centra-no1 font-light"
			aria-labelledby={id}
		>
			<div className="">{getTitle()}</div>
			<div className="grid grid-cols-1 sm:grid-cols-3 sm:text-xl sm:py-10 xl:py-10 sm:px-5 gap-2 lg:gap-x-10 lg lg:gap-y-5 xl:pl-12 xl:gap-x-10 xl:gap-y-2 xl:text-xl 2xl:gap-x-24 2xl:gap-y-5 2xl:pl-20 font-centra-book text-[20px]">
				{/* check items div */}
				{
					// eslint-disable-next-line react/prop-types
					subText.map((item) => (
						<div className="flex flex-row gap-4">
							<div className="w-5 h-5 mt-2">
								<img src={tickIcon} alt="" />
							</div>
							<div className="h-fit">{item.text}</div>
						</div>
					))
				}
			</div>
			<div className="mt-2">
				<a
					// eslint-disable-next-line react/prop-types
					href={`tel:${siteSettings?.phoneNumber}`}
					className="inline-flex items-center px-6 py-4 mb-2 mr-2 text-lg leading-6 text-white transition-colors bg-blue-400 border-2 border-blue-400 rounded-lg md:mb-0 hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 md:px-8 md:text-xl font-centra-medium focus:outline-0 focus:shadow-focus w-max"
				>
					{ctaLabel}
				</a>
			</div>
		</div>
	);
};

ServicesBannerCA.defaultProps = {
	id: 'hr-services',
};

ServicesBannerCA.propTypes = {
	id: PropTypes.string,
	iconCardsContent: PropTypes.shape({
		title: PropTypes.oneOfType([titleProp, PropTypes.string]),
		subText: PropTypes.string,
	}).isRequired,
};

export { ServicesBannerCA };
