// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { DefaultLayout } from '../layouts/default';

import { Head } from '../components/templates/head';

import { AdviceForm } from '../components/organisms/advice-form';
import { AdviceFormCA } from '../components/organisms/ca/forms/salesforce/advice';
import { SuccessForm } from '../components/organisms/success-form';
import { SuccessFormCA } from '../components/organisms/ca/forms/salesforce/success';
import { FAQ } from '../components/organisms/faq';
import { AdviceBanner } from '../components/organisms/advice-banner';
import { CardContainer } from '../components/organisms/card-container';
import { DownloadForm } from '../components/organisms/download-form';
import { RteContent } from '../components/organisms/rte-content';

import { ResourceHeader } from '../components/molecules/resource-header';
import { JumpTo } from '../components/molecules/jump-to';

import { Drawer } from '../components/atoms/drawer';
import { BreadCrumbs } from '../components/atoms/breadcrumbs';
import { LongIntro } from '../components/atoms/long-intro';

import {
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
} from '../utils/seo-schema';
import { getBreadcrumbs, pageTypes } from '../utils/url-helpers';
import { useDrawer } from '../contexts/drawer-context';
import { DownloadPromo } from '../components/atoms/download-promo';
import { DownloadSuccess } from '../components/atoms/download-success';
import { DownloadSuccessCA } from '../components/atoms/download-success-ca';

// markup
const DownloadPage = ({ data, location, parentPageData, locale }) => {
	const { drawerRef } = useDrawer();

	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');

	const downloadFormRef = useRef(null);
	const [downloadResponse, setDownloadResponse] = useState('');

	const metaContent = {
		noIndex: data.datoCmsDownloadPage?.noIndex,
		noFollow: data.datoCmsDownloadPage?.noFollow,
		metaInformation: data.datoCmsDownloadPage?.metaInformation,
		fallbackTitle: data.datoCmsDownloadPage?.title,
		fallbackDescription: data.datoCmsDownloadPage?.longIntroduction,
		canonicalUrl: data.datoCmsDownloadPage?.canonicalUrl || location.href,
	};

	const resourceHeaderContent = {
		id: data.datoCmsDownloadPage.originalId,
		title: data.datoCmsDownloadPage.title,
		categories: data.datoCmsDownloadPage.categories,
		author: data.datoCmsDownloadPage.author,
		createdDate: data.datoCmsDownloadPage.created,
		createdDateOrdinal: data.datoCmsDownloadPage.createdOrdinal,
		lastUpdated: data.datoCmsDownloadPage.meta.updatedAt,
		lastUpdatedOrdinal: data.datoCmsDownloadPage.meta.updatedAtOrdinal,
		pageUrl: location.href,
		tickList: data.datoCmsDownloadPage.promoTickList,
	};

	const successComponent =
		locale === 'en-CA' ? <DownloadSuccessCA /> : <DownloadSuccess />;

	const rteContent = {
		...data.datoCmsDownloadPage.content,
	};

	const faqContent = {
		title: data.datoCmsDownloadPage.faqContainer?.title,
		subText: data.datoCmsDownloadPage.faqContainer?.subText,
		questions: data.datoCmsDownloadPage.faqContainer?.questions.map(
			({ question, answer }) => ({
				question,
				answer: answer.value.document.children,
			})
		),
	};

	const downloadContainerContent = {
		totalCount: data.allDatoCmsDownloadPage.totalCount,
		cards: data.allDatoCmsDownloadPage.nodes,
	};

	const adviceBannerContent = {
		...data.datoCmsDownloadPage.adviceBanner,
		title: data.datoCmsDownloadPage.adviceBanner?.title.value.document
			.children,
		ctaLink: data.datoCmsDownloadPage.adviceBanner?.ctaLink,
	};

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.download,
			location,
			data: data.datoCmsDownloadPage,
			parentPageData,
		}),
		image: getImageSeoSchema(
			data.datoCmsDownloadPage?.mainHeader?.primaryImage
		),
	};

	const lastHubPage = location.pathname.substring(
		0,
		location.pathname.lastIndexOf('/')
	);

	const breadCrumbItems = getBreadcrumbs(
		data.datoCmsDownloadPage.internal.type,
		data.datoCmsDownloadPage,
		parentPageData
	);

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<BreadCrumbs breadCrumbItems={breadCrumbItems} />
			<ResourceHeader
				content={resourceHeaderContent}
				variant="Download"
				drawerRef={downloadFormRef}
			/>
			{data.datoCmsDownloadPage.longIntroduction ? (
				<LongIntro
					content={data.datoCmsDownloadPage.longIntroduction}
				/>
			) : null}

			{data.datoCmsDownloadPage.showJumpSection ? (
				<JumpTo content={rteContent} />
			) : null}

			<RteContent
				containerClassName="max-w-content mx-auto mb-14 md:mb-20 px-3 md:px-8 xl:px-0"
				content={rteContent}
				drawerRef={adviceFormRef}
			/>

			<DownloadPromo
				title={data.datoCmsDownloadPage.title}
				subText={data.datoCmsDownloadPage.downloadBannerCopy}
				drawerRef={downloadFormRef}
			/>
			<BreadCrumbs breadCrumbItems={breadCrumbItems} />
			{data.datoCmsDownloadPage.faqContainer &&
			data.datoCmsDownloadPage.faqContainerDisplayOnPage ? (
				<FAQ faqContent={faqContent} />
			) : null}

			{downloadContainerContent.totalCount && (
				<CardContainer
					cardContainerContent={{
						...downloadContainerContent,
						id: 'download-container',
						title: 'Related free downloads',
						to: `${lastHubPage}`,
						toAll: '/resource-hub',
						buttonCopy: 'Back to resource hub',
					}}
					variant="Center"
					cardVariant="Download"
				/>
			)}

			{data.datoCmsDownloadPage.adviceBanner &&
			data.datoCmsDownloadPage.adviceBannerDisplayOnPage ? (
				<AdviceBanner
					drawerRef={adviceFormRef}
					adviceBannerContent={adviceBannerContent}
				/>
			) : null}

			<Drawer
				label="Get your free download"
				ref={downloadFormRef}
				open={drawerRef === downloadFormRef}
				hasClose
				className="!p-0"
				elevation={20}
			>
				{downloadResponse === '' ? (
					<DownloadForm
						downloadId={data.datoCmsDownloadPage.downloadId}
						setResponseSent={setDownloadResponse}
						pageUrl={location.href}
						locale={locale}
					/>
				) : (
					successComponent
				)}
			</Drawer>

			{locale !== 'en-CA' ? (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceForm
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessForm
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			) : (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceFormCA
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessFormCA
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			)}
		</GoogleReCaptchaProvider>
	);
};

export const query = graphql`
	query (
		$slug: String!
		$relatedDownloadCategoryIds: [String!]!
		$locale: String!
	) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsDownloadPage(slug: { eq: $slug }, locale: { eq: $locale }) {
			title
			originalId
			internal {
				type
			}
			slug
			faqContainerDisplayOnPage
			adviceBannerDisplayOnPage
			categories {
				name
				slug
			}
			sectors {
				name
			}
			author {
				name
				image {
					alt
					url(imgixParams: { w: "44", h: "44" })
				}
				jobTitle
			}
			longIntroduction
			promoTickList {
				text
			}
			downloadId
			downloadType
			created
			createdOrdinal: created(formatString: "MMM Do YYYY")
			meta {
				updatedAt
				updatedAtOrdinal: updatedAt(formatString: "MMM Do YYYY")
			}
			showJumpSection
			content {
				value
				links {
					... on DatoCmsAdviceBanner {
						id: originalId
						title {
							value
						}
						content
						ctaLabel
						ctaLink
					}
					... on DatoCmsNewsletterSignUp {
						id: originalId
						subText
						newsletterSignUpTitle
					}
					... on DatoCmsBusinessHealthAssistantPromoBanner {
						id: originalId
						content
						linkCopy
						promoTitle {
							value
						}
					}
					... on DatoCmsAdviceForm {
						id: originalId
						adviceFormTitle
						subText
					}
				}
			}
			downloadBannerCopy
			faqContainer {
				title
				subText
				questions {
					question
					answer {
						value
					}
				}
			}
			adviceBanner {
				title {
					value
				}
				content
				ctaLabel
				ctaLink
			}
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			canonicalUrl
			promoTickList {
				text
			}
			downloadType
		}
		allDatoCmsDownloadPage(
			filter: {
				categories: {
					elemMatch: { id: { in: $relatedDownloadCategoryIds } }
				}
				slug: { ne: $slug }
				locale: { eq: $locale }
			}
			sort: { order: DESC, fields: created }
			limit: 3
		) {
			totalCount
			nodes {
				id
				internal {
					type
				}
				title
				slug
				intro
				downloadType
				author {
					id
					name
					jobTitle
					image {
						alt
						url(imgixParams: { w: "44", h: "44" })
					}
				}
				categories {
					id
					name
					slug
				}
				sectors {
					id
					name
				}
			}
		}
	}
`;

const WrappedDownload = ({ data, pageContext, location }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
	>
		<DownloadPage
			data={data}
			location={location}
			slug={pageContext.slug}
			parentPageData={pageContext.parentPageData}
			locale={pageContext.locale}
		/>
	</DefaultLayout>
);

export default WrappedDownload;
