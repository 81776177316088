import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { priceCalculatedProp, pricingCardProps } from '../../../types';
import { Button } from '../button';
import { sendTrackingData } from '../../../utils';
import * as pricingCardStyles from './index.module.css';
import blueTick from './blue_tick.svg';

const getCardPrice = ({ cardType, priceCalculated }) => {
	let price = '-';

	if (cardType === 'human resources' && priceCalculated?.hr) {
		price = priceCalculated.hr;
	}

	if (cardType === 'health and safety' && priceCalculated?.hs) {
		price = priceCalculated.hs;
	}

	if (cardType === 'combined' && priceCalculated?.combined) {
		price = priceCalculated.combined;
	}

	return price;
};

const getCombinedPrice = ({ cardType, priceCalculated }) => {
	let price = '';

	const hrPrice = parseFloat(priceCalculated.hr?.replace('$', ''));
	const healthAndSafetyPrice = parseFloat(
		priceCalculated.hs?.replace('$', '')
	);

	if (cardType === 'combined') {
		const sum = hrPrice + healthAndSafetyPrice;
		if (Number.isNaN(sum)) {
			price = '';
		} else {
			price = `$${sum.toFixed(2)}`;
		}
	}

	return price;
};

const PricingCard = ({
	content,
	employeeCount,
	priceCalculated,
	index,
	quoteButtonCopy,
}) => {
	const buttonText = quoteButtonCopy;
	const { title, cardType, description, services } = content;

	const handleClickTracking = (clickType) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			component_name: 'pricing-card',
			click_type: clickType,
			click_action: 'card click',
			click_state: `${employeeCount}_no_employees`,
			click_title: title,
			click_text: buttonText,
			click_index: index + 1,
		});
	};

	const priceBlock = () => (
		<>
			<div className="flex flex-col items-start justify-between xs:items-start xs:flex-row text-left pt-2">
				<div className="text-sm xl:max-w-none md:max-w-[100px] text-center">
					<strong
						className={clsx(
							'block text-4xl md:text-4xl',
							'flex flex-col text-blue-400 font-centra-book mt-8 text-center'
						)}
					>
						{cardType === 'combined' &&
						priceCalculated.combined !== '-' ? (
							<div className="flex flex-row gap-2">
								<div className="line-through text-[#B3B3B3] decoration-[#E30138]">
									{getCombinedPrice({
										cardType,
										priceCalculated,
									})}
								</div>
								<div className="text-left">
									{getCardPrice({
										cardType,
										priceCalculated,
									})}
								</div>
							</div>
						) : (
							<span className="text-left">
								{getCardPrice({ cardType, priceCalculated })}
							</span>
						)}
					</strong>
					<p className="text-blue-400 text-left">
						per employee, per month
					</p>
				</div>
			</div>
		</>
	);

	const borderStyles = {
		combined: {
			className: 'bg-blue-400 h-14 rounded-t-[0.75rem] py-4',
			content: (
				<h1 className="mx-auto text-center text-white text-xl font-medium">
					Most Popular
				</h1>
			),
		},
		'health and safety': {
			className: 'bg-blue-300 h-3 rounded-t-[0.75rem]',
			content: null,
		},
		'human resources': {
			className: 'bg-blue-200 h-3 rounded-t-[0.75rem]',
			content: null,
		},
	};

	const borderStyle = borderStyles[cardType];

	return (
		<div
			className="flex flex-col col-span-12 md:col-span6 lg:col-span-4 mt-auto transform transition duration-200 
		hover:scale-105"
		>
			{borderStyle ? (
				<div className={borderStyle.className}>
					{borderStyle.content}
				</div>
			) : null}
			<article
				className={`flex flex-col col-span-12 md:col-span-6 lg:col-span-4 bg-white border-2 border-blue-200 grow shadow-[10px_2px_15px_0px_rgba(29,41,81,0.1)] rounded-b-[0.75rem] ${pricingCardStyles.pricingCard}`}
			>
				<header className="flex flex-col p-s-f md:px-7">
					{cardType === 'human resources' ? (
						<h2 className="text-left text-lg md:text-2xl font-centra-medium text-[#1F2E7A]">
							{title}
						</h2>
					) : (
						<h2 className="text-left text-lg md:text-2xl font-centra-medium text-[#1F2E7A]">
							{title}
						</h2>
					)}
					<p className="text-left h-2 mt-2 mb-4 font-centra-no1 text-[#787777] text-[15px] leading-5">
						{description}
					</p>
					{priceBlock(true)}
				</header>
				<div className="flex flex-col px-8 items-start justify-between xs:items-start xs:flex-row">
					<Button
						onClick={() => {
							handleClickTracking('primary');
							if (typeof window !== 'undefined') {
								window.location.href = '#advice-form';
							}
						}}
						className="!font-centra-book hidden md:block"
					>
						{buttonText}
					</Button>
					<Button
						onClick={() => {
							handleClickTracking('primary');
							if (typeof window !== 'undefined') {
								window.location.href = '#adviceform';
							}
						}}
						className="!font-centra-book block md:hidden"
					>
						{buttonText}
					</Button>
				</div>
				<div className="px-8 pt-6 sm:pt-12">
					<hr className="border-[#D7DDF9]" />
				</div>
				<div className="flex flex-col gap-3 px-8 py-6 font-centra-no1 font-light xl:text-[13px] 2xl:text-[14.3px]">
					<div className="">Included in this package:</div>
					<div>
						{services.map((elm) => (
							<div className="flex flex-row gap-4 items-center">
								<div>
									<img
										className="min-h-[12px] min-w-[12px]"
										src={blueTick}
										alt=""
									/>
								</div>
								<div>{elm.text}</div>
							</div>
						))}
					</div>
				</div>
			</article>
		</div>
	);
};

PricingCard.defaultProps = {
	index: 0,
	employeeCount: 0,
	priceCalculated: {
		hr: '-',
		hs: '-',
		combined: '-',
	},
};

PricingCard.propTypes = {
	priceCalculated: priceCalculatedProp,
	content: pricingCardProps.isRequired,
	index: PropTypes.number,
	employeeCount: PropTypes.number,
	quoteButtonCopy: PropTypes.string.isRequired,
};

export { PricingCard };
