import React from 'react';

export const createHeadingHighlightCA = ({
	headings,
	variant = 'red',
	className,
	highlightClassName = '',
}) => {
	if (variant === 'blue') {
		return (
			<div className={className}>
				{headings.map((item) =>
					// eslint-disable-next-line no-nested-ternary
					item.marks ? (
						<span
							key={`heading-text-${item.value}-${Math.random()}`}
							className="text-white"
						>
							<p className="inline-block px-2 mr-2 font-bold text-center bg-blue-400 rotate-6 font-castedown-regular">
								{item.value}
							</p>
						</span>
					) : item.value.includes('\n') ? (
						// Check for new lines and handle them
						item.value.split('\n').map((line, index) =>
							// We don't want the first one as it'll be part of the original text
							index > 0 ? (
								<span
									key={`heading-text-${line}-${Math.random()}`}
								>
									<br />
									{line}
								</span>
							) : (
								<span key={`heading-text-${line}`}>{line}</span>
							)
						)
					) : (
						<span key={`heading-text-${item.value}`}>
							{item.value}
						</span>
					)
				)}
			</div>
		);
	}

	if (variant === 'black') {
		return (
			<div className={className}>
				{headings.map((item) =>
					// eslint-disable-next-line no-nested-ternary
					item.marks ? (
						<span
							key={`heading-text-${item.value}-${Math.random()}`}
							className={`${highlightClassName}`}
						>
							{item.value}
						</span>
					) : item.value.includes('\n') ? (
						item.value.split('\n').map((line, index) =>
							index > 0 ? (
								<span
									className="text-[#1D2951]"
									key={`heading-text-${line}-${Math.random()}`}
								>
									<br />
									{line}
								</span>
							) : (
								<span
									className="text-[#1D2951]"
									key={`heading-text-${line}`}
								>
									{line}
								</span>
							)
						)
					) : (
						<span
							className="text-[#1D2951]"
							key={`heading-text-${item.value}`}
						>
							{item.value}
						</span>
					)
				)}
			</div>
		);
	}

	if (variant === 'red') {
		return (
			<span
				className={`text-3xl md:text-5xl font-castledown-regular leading-[1.2] md:leading-[1.3] ${className}`}
			>
				{headings.map((item) =>
					// eslint-disable-next-line no-nested-ternary
					item.marks ? (
						<span
							key={`heading-text-${item.value}-${Math.random()}`}
							className="text-[#E30138] pb-1 md:bg-[length:100%_8px]"
						>
							{item.value}
						</span>
					) : item.value.includes('\n') ? (
						// Check for new lines and handle them
						item.value.split('\n').map((line, index) =>
							// We don't want the first one as it'll be part of the original text
							index > 0 ? (
								<span
									className="text-[#1D2951]"
									key={`heading-text-${line}-${Math.random()}`}
								>
									<br />
									{line}
								</span>
							) : (
								<span
									className="text-[#1D2951]"
									key={`heading-text-${line}`}
								>
									{line}
								</span>
							)
						)
					) : (
						<span
							className="text-[#1D2951]"
							key={`heading-text-${item.value}`}
						>
							{item.value}
						</span>
					)
				)}
			</span>
		);
	}

	if (variant === 'red-2') {
		return (
			<span
				className={`text-center md:text-left text-3xl md:text-2xl font-castledown-regular leading-[1.2] md:leading-[1.3] ${className}`}
			>
				{headings.map((item) =>
					// eslint-disable-next-line no-nested-ternary
					item.marks ? (
						<span
							key={`heading-text-${item.value}-${Math.random()}`}
							className="text-[#E30138] pb-1 md:bg-[length:100%_8px]"
						>
							{item.value}
						</span>
					) : item.value.includes('\n') ? (
						// Check for new lines and handle them
						item.value.split('\n').map((line, index) =>
							// We don't want the first one as it'll be part of the original text
							index > 0 ? (
								<span
									className="text-[#1D2951]"
									key={`heading-text-${line}-${Math.random()}`}
								>
									<br />
									{line}
								</span>
							) : (
								<span
									className="text-[#1D2951]"
									key={`heading-text-${line}`}
								>
									{line}
								</span>
							)
						)
					) : (
						<span
							className="text-[#1D2951]"
							key={`heading-text-${item.value}`}
						>
							{item.value}
						</span>
					)
				)}
			</span>
		);
	}

	if (variant === 'white') {
		return (
			<div className={`text-2xl ${className}`}>
				{headings.map((item) =>
					// eslint-disable-next-line no-nested-ternary
					item.marks ? (
						<span
							key={`heading-text-${item.value}-${Math.random()}`}
							className="text-red"
						>
							{item.value}
						</span>
					) : item.value.includes('\n') ? (
						item.value.split('\n').map((line, index) =>
							index > 0 ? (
								<span
									className="text-[#1D2951]"
									key={`heading-text-${line}-${Math.random()}`}
								>
									<br />
									{line}
								</span>
							) : (
								<span
									className="text-[#1D2951]"
									key={`heading-text-${line}`}
								>
									{line}
								</span>
							)
						)
					) : (
						<span
							className="text-white"
							key={`heading-text-${item.value}`}
						>
							{item.value}
						</span>
					)
				)}
			</div>
		);
	}

	if (variant === 'purple') {
		return (
			<div className={`text-[16px] ${className}`}>
				{headings.map((item) =>
					// eslint-disable-next-line no-nested-ternary
					item.marks ? (
						<span
							key={`heading-text-${item.value}-${Math.random()}`}
							className="text-[#9CAAF0]"
						>
							{item.value}
						</span>
					) : item.value.includes('\n') ? (
						item.value.split('\n').map((line, index) =>
							index > 0 ? (
								<span
									className="text-[#9CAAF0]"
									key={`heading-text-${line}-${Math.random()}`}
								>
									<br />
									{line}
								</span>
							) : (
								<span
									className="text-[#9CAAF0]"
									key={`heading-text-${line}`}
								>
									{line}
								</span>
							)
						)
					) : (
						<span
							className="text-white"
							key={`heading-text-${item.value}`}
						>
							{item.value}
						</span>
					)
				)}
			</div>
		);
	}
	if (variant === '') {
		return (
			<div className={className}>
				{headings.map((item) =>
					// eslint-disable-next-line no-nested-ternary
					item.marks ? (
						<span
							key={`heading-text-${item.value}-${Math.random()}`}
							className=""
						>
							{item.value}
						</span>
					) : item.value.includes('\n') ? (
						item.value.split('\n').map((line, index) =>
							index > 0 ? (
								<span
									className=""
									key={`heading-text-${line}-${Math.random()}`}
								>
									<br />
									{line}
								</span>
							) : (
								<span className="" key={`heading-text-${line}`}>
									{line}
								</span>
							)
						)
					) : (
						<span className="" key={`heading-text-${item.value}`}>
							{item.value}
						</span>
					)
				)}
			</div>
		);
	}
	if (variant === 'brand-blue-underline') {
		return (
			<div className={className}>
				{headings.map((item) =>
					// Check to see if there are any highlighted words
					// eslint-disable-next-line no-nested-ternary
					item.marks ? (
						<span
							key={`heading-text-${item.value}-${Math.random()}`}
							className={`
								text-blue-400 pb-1 bg-no-repeat bg-underlineblue bg-bottom bg-[length:100%_6px] md:bg-[length:100%_8px] ${highlightClassName}`}
						>
							{item.value}
						</span>
					) : item.value.includes('\n') ? (
						// Check for new lines and handle them
						item.value.split('\n').map((line, index) =>
							// We don't want the first one as it'll be part of the original text
							index > 0 ? (
								<span
									key={`heading-text-${line}-${Math.random()}`}
									className="text-blue-600"
								>
									<br />
									{line}
								</span>
							) : (
								<span
									key={`heading-text-${line}`}
									className="text-blue-600"
								>
									{line}
								</span>
							)
						)
					) : (
						<span
							key={`heading-text-${item.value}`}
							className="text-blue-600"
						>
							{item.value}
						</span>
					)
				)}
			</div>
		);
	}
	if (variant === 'brand-red-underline') {
		return (
			<div className={className}>
				{headings.map((item) =>
					// Check to see if there are any highlighted words
					// eslint-disable-next-line no-nested-ternary
					item.marks ? (
						<span
							key={`heading-text-${item.value}-${Math.random()}`}
							className={`
								text-brand-red-400 pb-1 bg-no-repeat bg-underline bg-bottom bg-[length:100%_6px] md:bg-[length:100%_8px] ${highlightClassName}`}
						>
							{item.value}
						</span>
					) : item.value.includes('\n') ? (
						// Check for new lines and handle them
						item.value.split('\n').map((line, index) =>
							// We don't want the first one as it'll be part of the original text
							index > 0 ? (
								<span
									key={`heading-text-${line}-${Math.random()}`}
									className="brand-red-400"
								>
									<br />
									{line}
								</span>
							) : (
								<span
									key={`heading-text-${line}`}
									className="brand-red-400"
								>
									{line}
								</span>
							)
						)
					) : (
						<span
							key={`heading-text-${item.value}`}
							className="brand-red-400"
						>
							{item.value}
						</span>
					)
				)}
			</div>
		);
	}
};
