/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { HeadingTagLine } from '../../atoms/heading-tag-line';
import { HeadingTagLine as HeadingTagLineBlue } from '../../atoms/heading-tag-line-blue-ca';
import * as styles from './index.module.css';

import { titleProp } from '../../../types';
import { Button } from '../../atoms/button-ca';
import { useDrawer } from '../../../contexts/drawer-context';
import CTAbuttonsCA from '../../atoms/cta-buttons-ca';
import tick from './tick.svg';

const CorV2CA = ({
	id,
	containerClassName,
	drawerRef,
	modalRef,
	corContent,
	className,
	isPPC = false,
}) => {
	const { setDrawerRef } = useDrawer();
	return (
		<div
			className={`w-full ${
				isPPC ? 'bg-none' : styles.corGradientBg
			} ${className}`}
		>
			<div
				className={clsx(
					'mt-10 lg:pt-5 scroll-mt-[79x] md:scroll-mt-[160px] scroll-mt-[140px] px-4 mx-auto md:px-8 max-w-m-screen my-xl-f xl:pt-2xl-f xl:pb-xl-f bg-none',
					containerClassName
				)}
				aria-labelledby={id}
			>
				<div className="lg:px-10 md:container md:mx-auto">
					{isPPC ? (
						<HeadingTagLineBlue
							id={id}
							title={corContent.title.value.document.children}
							subText={corContent.subText}
							headingClassNames="lg:text-4xl xl:text-5xl"
							subTextClassNames="mx-auto max-w-2xl font-centra-light md:text-[20px] md:pt-1"
						/>
					) : (
						<HeadingTagLine
							id={id}
							title={corContent.title.value.document.children}
							subText={corContent.subText}
							headingClassNames="lg:text-4xl xl:text-5xl"
							subTextClassNames="mx-auto max-w-2xl font-centra-light md:text-[20px] md:pt-1"
						/>
					)}
				</div>
				{/* desktop view */}
				<div className="hidden lg:block flex flex-col items-stretch justify-center text-center pt-12 pb-10 bg-none">
					<div className="grid auto-rows-min grid-cols-1 text-[#1F2E7A] font-centra-no1 text-2xl font-black text-center">
						<div className="flex flex-row items-stretch gap-10 justify-center text-white font-centra-light text-4xl ">
							<div className="bg-blue-300 w-full rounded-t-[0.75rem] p-3 shadow-lg border-x-1 border-[#DDDDDD]">
								COR Plus
							</div>
							<div className="bg-blue-400 w-full rounded-t-[0.75rem] p-3 shadow-lg border-x-1 border-[#DDDDDD]">
								COR Premium
							</div>
						</div>
						<div className="flex flex-row items-stretch gap-10 text-xs font-light text-black justify-center text-left">
							<div className="w-full bg-white shadow-lg border-x-1 border-[#DDDDDD] px-5 pt-7">
								<div
									key="cor-plus-self"
									className="font-centra-light text-[17px] text-left px-2"
								>
									<div className="flex flex-row gap-4 pb-1">
										<div className="min-w-[19px] min-w-[19px]">
											&nbsp;
										</div>
										<div className="font-centra-book text-[20px] text-blue-400 pb-2">
											{corContent?.corCards[0]?.title}
										</div>
									</div>
									{corContent?.corCards[0]?.items?.map(
										(item) => (
											<div className="flex flex-row gap-4 pb-1">
												<div className="pt-[0.1rem] min-w-[19px] min-w-[19px]">
													<img
														src={tick}
														alt="Tick"
													/>
												</div>
												<div
													key={item.text}
													className="lg:leading-5 text-[17px] pb-2"
												>
													{item.text}
												</div>
											</div>
										)
									)}
								</div>
								<div className="flex items-center justify-center space-x-4 mx-3 mb-4">
									<hr className="flex-1 border-t border-gray-300" />
									<span className="mx-2 text-gray-600 font-centra-book text-[16px]">
										OR
									</span>
									<hr className="flex-1 border-t border-gray-300" />
								</div>
								<div
									key="cor-plus-secor"
									className="font-centra-light text-[17px] text-left px-2"
								>
									<div className="flex flex-row gap-4 pb-1">
										<div className="min-w-[19px] min-w-[19px]">
											&nbsp;
										</div>
										<div className="font-centra-book text-[20px] text-blue-400 pb-2">
											{corContent?.corCards[1]?.title}
										</div>
									</div>
									{corContent.corCards[1]?.items?.map(
										(item) => (
											<div className="flex flex-row gap-4 pb-1">
												<div className="pt-[0.1rem] min-w-[19px] min-w-[19px]">
													<img
														src={tick}
														alt="Tick"
													/>
												</div>
												<div
													key={item.text}
													className="lg:leading-5 text-[17px] pb-2"
												>
													{item.text}
												</div>
											</div>
										)
									)}
								</div>
								<div className="flex items-center justify-center space-x-4 mx-3 mb-4">
									<hr className="flex-1 border-t border-gray-300" />
									<span className="mx-2 text-gray-600 font-centra-book text-[16px]">
										OR
									</span>
									<hr className="flex-1 border-t border-gray-300" />
								</div>
								<div
									key="cor-plus-maintenance"
									className="font-centra-light text-[17px] text-left px-2"
								>
									<div className="flex flex-row gap-4 pb-1">
										<div className="min-w-[19px] min-w-[19px]">
											&nbsp;
										</div>
										<div className="font-centra-book text-[20px] text-blue-400 pb-2">
											{corContent?.corCards[2]?.title}
										</div>
									</div>
									{corContent.corCards[2]?.items?.map(
										(item) => (
											<div className="flex flex-row gap-4 pb-1">
												<div className="pt-[0.1rem] min-w-[19px] min-w-[19px]">
													<img
														src={tick}
														alt="Tick"
													/>
												</div>
												<div
													key={item.text}
													className="lg:leading-5 text-[17px] pb-2"
												>
													{item.text}
												</div>
											</div>
										)
									)}
								</div>
							</div>
							<div className="w-full bg-white shadow-lg px-5 pt-8 border-x-1 border-[#DDDDDD]">
								<div
									key="cor-premium-list"
									className="font-centra-light text-[17px] text-left px-2"
								>
									{corContent.corCards[
										corContent.corCards.length - 1
									]?.items?.map((item) => (
										<div className="flex flex-row gap-4 pb-1">
											<div className="pt-[0.1rem] min-w-[19px] min-w-[19px]">
												<img src={tick} alt="Tick" />
											</div>
											<div
												key={item.text}
												className="lg:leading-5 text-[17px] pb-2"
											>
												{item.text}
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
						<div className="flex flex-row items-stretch gap-10 justify-center text-left">
							<div className="w-full bg-white rounded-b-[0.75rem] shadow-lg px-7 pt-0 pb-2 border-x-1 border-b-1 border-[#DDDDDD]">
								<CTAbuttonsCA
									primaryLinkCopy={
										corContent?.corCards[0]?.buttonCopy ||
										corContent?.corCards[1]?.buttonCopy ||
										corContent?.corCards[2]?.buttonCopy ||
										'Get COR Plus'
									}
									primaryLink={null}
									primaryLinkOpenForm
									secondaryLinkCopy={null}
									secondaryLink={null}
									secondaryLinkOpenForm={null}
									modalRef={isPPC && modalRef}
									useModalForm={!!isPPC}
									drawerRef={!isPPC && drawerRef}
									variant="col"
									className="font-light"
									buttonVariant={isPPC ? 'Red' : 'Default'}
								/>
							</div>
							<div className="w-full bg-white rounded-b-[0.75rem] shadow-lg px-7 pt-0 pb-2 border-x-1 border-b-1 border-[#DDDDDD]">
								<CTAbuttonsCA
									primaryLinkCopy={
										corContent?.corCards[
											corContent.corCards.length - 1
										]?.buttonCopy || 'Get COR Premium'
									}
									primaryLink={null}
									primaryLinkOpenForm
									secondaryLinkCopy={null}
									secondaryLink={null}
									secondaryLinkOpenForm={null}
									modalRef={isPPC && modalRef}
									useModalForm={!!isPPC}
									drawerRef={!isPPC && drawerRef}
									variant="col"
									className="font-light"
									buttonVariant={isPPC ? 'Red' : 'Default'}
								/>
							</div>
						</div>
					</div>
				</div>

				{/* mobile view */}
				<div className="block lg:hidden bg-white flex flex-col text-center w-full gap-10 pt-8">
					<div className="flex flex-col items-stretch gap-5 justify-center font-centra-light text-2xl bg-white rounded-[0.75rem] shadow-lg w-full border-1 border-[#DDDDDD]">
						<div className="bg-blue-300 p-3  rounded-t-[0.75rem] text-white">
							COR Plus
						</div>
						<div className="flex flex-row items-stretch gap-10 text-xs font-light text-black justify-center text-left">
							<div className="w-full bg-white px-5 pt-5 -mb-1">
								<div
									key="cor-plus-self"
									className="font-centra-light text-[17px] text-left px-2"
								>
									<div className="flex flex-row gap-4 pb-1">
										<div className="min-w-[19px] min-w-[19px]">
											&nbsp;
										</div>
										<div className="font-centra-book text-[20px] leading-5 text-blue-400 pb-2">
											{corContent?.corCards[0]?.title}
										</div>
									</div>
									{corContent?.corCards[0]?.items?.map(
										(item) => (
											<div className="flex flex-row gap-4 pb-1">
												<div className="pt-[0.1rem] min-w-[19px] min-w-[19px]">
													<img
														src={tick}
														alt="Tick"
													/>
												</div>
												<div
													key={item.text}
													className="leading-5 text-[17px] pb-2"
												>
													{item.text}
												</div>
											</div>
										)
									)}
								</div>
								<div className="flex items-center justify-center space-x-4 mx-3 mb-4">
									<hr className="flex-1 border-t border-gray-300" />
									<span className="mx-2 text-gray-600 font-centra-book text-[16px]">
										OR
									</span>
									<hr className="flex-1 border-t border-gray-300" />
								</div>
								<div
									key="cor-plus-secor"
									className="font-centra-light text-[17px] text-left px-2"
								>
									<div className="flex flex-row gap-4 pb-1">
										<div className="min-w-[19px] min-w-[19px]">
											&nbsp;
										</div>
										<div className="font-centra-book leading-5 text-[20px] text-blue-400 pb-2">
											{corContent?.corCards[1]?.title}
										</div>
									</div>
									{corContent.corCards[1]?.items?.map(
										(item) => (
											<div className="flex flex-row gap-4 pb-1">
												<div className="pt-[0.1rem] min-w-[19px] min-w-[19px]">
													<img
														src={tick}
														alt="Tick"
													/>
												</div>
												<div
													key={item.text}
													className="leading-5 text-[17px] pb-2"
												>
													{item.text}
												</div>
											</div>
										)
									)}
								</div>
								<div className="flex items-center justify-center space-x-4 mx-3 mb-4">
									<hr className="flex-1 border-t border-gray-300" />
									<span className="mx-2 text-gray-600 font-centra-book text-[16px]">
										OR
									</span>
									<hr className="flex-1 border-t border-gray-300" />
								</div>
								<div
									key="cor-plus-maintenance"
									className="font-centra-light text-[17px] text-left px-2"
								>
									<div className="flex flex-row gap-4 pb-1">
										<div className="min-w-[19px] min-w-[19px]">
											&nbsp;
										</div>
										<div className="font-centra-book leading-5 text-[20px] text-blue-400 pb-2">
											{corContent?.corCards[2]?.title}
										</div>
									</div>
									{corContent.corCards[2]?.items?.map(
										(item) => (
											<div className="flex flex-row gap-4 pb-1">
												<div className="pt-[0.1rem] min-w-[19px] min-w-[19px]">
													<img
														src={tick}
														alt="Tick"
													/>
												</div>
												<div
													key={item.text}
													className="leading-5 text-[17px] pb-2"
												>
													{item.text}
												</div>
											</div>
										)
									)}
								</div>
							</div>
						</div>
						<div>
							<Button
								className="text-lg mb-10"
								onClick={() => {
									drawerRef
										? setDrawerRef(drawerRef)
										: (window.location.href =
												'#adviceform');
								}}
								variant={isPPC ? 'Red' : 'Default'}
							>
								{corContent?.corCards[0]?.buttonCopy ||
									corContent?.corCards[1]?.buttonCopy ||
									corContent?.corCards[2]?.buttonCopy ||
									'Get COR Plus'}
							</Button>
						</div>
					</div>
					<div className="flex flex-col items-stretch gap-5 justify-center font-centra-light text-2xl bg-white rounded-[0.75rem] w-full border-1 border-[#DDDDDD]">
						<div className="bg-blue-400 p-3 rounded-t-[0.75rem] text-white">
							{
								corContent.corCards[
									corContent.corCards.length - 1
								]?.title
							}
						</div>
						<div className="w-full bg-white px-5 pt-5 -mb-1">
							<div
								key="cor-premium-list"
								className="font-centra-light text-[17px] text-left px-2"
							>
								{corContent.corCards[
									corContent.corCards.length - 1
								]?.items?.map((item) => (
									<div className="flex flex-row gap-4 pb-1">
										<div className="pt-[0.1rem] min-w-[19px] min-w-[19px]">
											<img src={tick} alt="Tick" />
										</div>
										<div
											key={item.text}
											className="leading-5 text-[17px] pb-2"
										>
											{item.text}
										</div>
									</div>
								))}
							</div>
						</div>
						<div>
							<Button
								className="text-lg mb-10"
								onClick={() => {
									drawerRef
										? setDrawerRef(drawerRef)
										: (window.location.href =
												'#adviceform');
								}}
								variant={isPPC ? 'Red' : 'Default'}
							>
								{corContent?.corCards[
									corContent.corCards.length - 1
								]?.buttonCopy || 'Get COR Premium'}
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

CorV2CA.defaultProps = {
	id: 'hr-services',
	containerClassName: '',
};

CorV2CA.propTypes = {
	id: PropTypes.string,
	iconCardsContent: PropTypes.shape({
		title: PropTypes.oneOfType([titleProp, PropTypes.string]),
		subText: PropTypes.string,
		uspCards: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				content: PropTypes.string.isRequired,
				linkCopy: PropTypes.string,
				link: PropTypes.string,
				icon: PropTypes.string.isRequired,
			})
		).isRequired,
	}).isRequired,
	containerClassName: PropTypes.string,
};

export { CorV2CA };
